.b-reorder{
	/*@include vw($padding: 30 0 0);*/
	@include respond-to(small){
		padding: 0;
	};
	&_hero{
		/*		.g-site_width{
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;
					@include respond-to(small){
						flex-wrap: wrap;
					};
				}*/
		.b-order_recommend_wrap{
			&.order{
				display: none;
			}
			&.reorder{
				display: block;
				header{
					@include vw($margin-bottom: 90);
					p{
						&:nth-child(1){
							color: #fff;
							font-family: $ff;
							@include vw($font-size: 42,
								$margin: 0);
						}
						&:nth-child(2){
							@include vw($font-size: 54,
								$margin: 0);
							color: $brand;
						}
						&:nth-child(3){
							color: #fff;
							@include vw($font-size: 50,
								$margin: 0);
						}

					}
				}
				.b-order_recommend_btn{
					@include vw($margin: 60 0 0);
				}
			}
		}
		.b-prestep{
			.b-order_reveal{
				display: block;
			}
		}
		.b-order_reveal{
			display: none;
		}
		.b-order_header{
			flex-basis: 100%;
			@include vw($margin-bottom: 0);
		}
		h1{
			flex-basis: 100%;
			text-align: center;
			flex-basis: 100%;
			/*@include vw($margin-bottom: 80);*/
			@include respond-to(small){
				/*margin-bottom: 35px;*/
			};
			@include respond-to(xs){
				font-size: 28px;
				/*margin-bottom: 10px;*/
			};

		}
		&_col{
			flex-basis: 47%;
			@include respond-to(small){
				flex-basis: 100%;
			};
		}
		&_choose{
			background: #fff;
			text-align: center;
			position: relative;
			z-index: 2;
			@include vw($padding-bottom: 10,
				$border: 9 solid $brand,
				$border-top: 0);
			header{
				background: $brand;
				color: #fff;

				@include vw($font-size: 44,
					$padding: 24 10 15,
					$margin-bottom: 30,
					$letter-spacing: 3.5);
				@include respond-to(xs){
					font-size: 22px;
				};
				@include respond-to(350){
					font-size: 16px;
				};
			}
			&_tab{
				ul{
					display: flex;
					justify-content: center;
					align-items: center;
					li{
						@include vw($margin: 0 5 17);
						flex-basis: 27%;
						@include respond-to(xs){
							flex-basis: 30%;
						};
						a{
							background: #b3b3b3;
							text-align: center;
							font-family: $ff;
							color: #fff;
							display: block;
							@include vw($padding: 15 10 12,
								$font-size: 34);
							@include respond-to(xs){
								font-size: 18px;
							};
							@include respond-to(340){
								font-size: 16px;
							};
							&.active{
								background: $brand;

							}
							&:hover{
								background: $brand;
							}
						}

					}
				}
			}
			&_regular{
				@include vw($margin: 30 0 5,
					$font-size: 32);
				font-family: $ff;
				color: $text;
				line-height: 1;
			}
			&_saving{
				@include vw($margin: 0 0 0,
					$font-size: 32);
				line-height: 1;
				color: $text;
			}
			&_price{
				@include vw($margin: 5 0 0,
					$font-size: 80);
				color: $brand;
				line-height: 1;
				font-weight: bold;
				*{
					font-weight: bold;
				}
			}
			.b-subscribe_container{
				@include vw($min-height: 42,
					$margin-top: 8);
			}
			&_subprice{
				@include vw($margin: 0 0 15,
					$font-size: 22);

			}

			&_plus{
				color: $brand;
				font-weight: bold;
				display: none;
			}
			&_subscribe{
				@include vw($margin: 0 0 0,
					$font-size: 34);

				color: $red;
				display: none;
			}
			&_shipping{
				@include vw($margin: 10 0 10,
					$font-size: 19,
					$letter-spacing: 1.5);
			}
			.l-separate{
				@include vw($margin-top: 15);
			}
		}
		&_subscribe{

			&_body{
				@include vw($padding-bottom: 40,
					$border: 9 solid $brand,
					$border-top: 0);
				background: rgba(40, 171, 227, 0.2)
			}
			header{
				background: $brand;
				color: #fff;
				text-align: center;
				@include vw($font-size: 44,
					$padding: 24 10 15,
					$letter-spacing: 1.5);
				@include respond-to(xs){
					font-size: 22px;
				};
				@include respond-to(350){
					font-size: 16px;
				};
			}
			ul{
				@include vw($padding: 55 30 35 70);
				text-align: left;
				@include respond-to(xs){
					padding: 25px 11px 14.5px 10px;
				};
				li{
					position: relative;
					@include vw($font-size: 34,
						$padding-left: 50,
						$margin-bottom: 17);
					@include respond-to(xs){
						font-size: 21.8px;
					};
					&:before{
						content:'\2714';
						position: absolute;
						left: 0;
						@include vw($font-size: 38);
					}	
				}
			}
			&_form{
				width: 85%;
				margin: 0 auto;
				background: $brand;
				@include vw($padding: 15 20 20);
				cursor: pointer;
				transition: all 0.2s cubic-bezier(0.4, 0, 1, 1);
				@include respond-to(xs){
					width: 95%;
				};
				&:hover{
					background: #f7931e;
				}
				&.active{
					background: #f7931e;
					.b-reorder_hero_subscribe_check{
						text-align: center;
						@include vw($padding-top: 3);
						&:before{
							content:'\2714'; 
							line-height: 1;
							color: #000;
							@include vw($font-size: 46);
						}
					}
				}
			}
			&_top{
				display: flex;
				align-items: flex-start;
				position: relative;

				p{
					line-height: 1;
					flex-grow: 0;
					text-align: left;
					@include vw($margin-bottom: 0,
						$font-size: 36);
					@include respond-to(xs){
						font-size: 22.2px;
					};
					span{
						&:first-child{
							font-family: AvenirHeavy;
							display: inline-block;
							@include vw($margin-right: 5);
						}
						&:nth-child(2){
							@include vw($margin-top: 10,
								$letter-spacing: 2);
							display: block;
						}
					}
					.b-cancel{

					}
				}
			}
			&_check{
				@include vw($width: 45,
					$height: 45,
					$margin-right: 15,
					$font-size: 26);
				background: #fff;
				flex-grow: 0;


			}
			&_bottom{
				font-style: italic;
				@include vw($font-size: 20);
			}
		}
		&_options{
			flex-basis: 100%;
			@include vw($padding: 90 0 30);
			@include respond-to(small){
				padding: 35px 0;
			};
			ul{
				width: 100%;
				display: flex;
				justify-content: space-between;
				@include respond-to(520){
					flex-wrap: wrap;
				};
				li{
					flex-basis: 24%;
					text-align: center;
					@include respond-to(520){
						flex-basis: 100%;
						margin-bottom: 10px;
					};
					a{
						display: block;
						background: #e6e6e6;
						@include vw($padding: 15 10 10);
						@include respond-to(small){
							font-size: 14px;
						};
						@include respond-to(520){
							font-size: 16px;
						};
						&:hover, &:active, &:focus{
							background: $brand;	
							color: #fff;
						}
					}
				}
			}
		}
	}
	.b-guarantee{
		background: #ccc;

		@include vw($padding: 40 0 60);
		.g-site_width{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			@include respond-to(530){
				flex-wrap: wrap;
			};
		}
		&_img{
			flex-basis: 19%;
			margin-right: 6%;
			@include respond-to(530){
				flex-basis: 100%;
				margin-right: 0%;

			};
			img{
				width: 100%;
				display: block;
				@include respond-to(530){
					max-width: 150px;
					margin: 20px auto;
				};
			}
		}
		&_text{
			flex-basis: 70%;
			@include respond-to(530){
				flex-basis: 100%;
				margin-right: 0%;
			};
			h2{
				@include vw($font-size: 36,
					$margin-bottom: 10,
					$letter-spacing: 2);
			}
			.l-separate{
				margin: 0;
				background: #fff;
				@include vw(
				$margin: 10 0 20);
			}
			p{
				@include vw($margin-bottom: 0,
					$font-size: 28);
				line-height: 1.6;
			}
		}
	}
	.b-faq{	
		@include vw($padding: 30 0 90);
		h2{
			@include vw($margin-bottom: 85);
		}
		section{
			@include vw($margin: 15 0 0);
		}
		h3{
			@include vw($font-size: 26,
				$margin-bottom: 20);
		}
		p{
			@include vw($font-size: 26);
		}
	}
	.b-about{
		background: rgba(41, 170, 227, 0.38);
		@include vw($padding: 55 0);
		.g-site_width{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			@include respond-to(small){
				flex-wrap: wrap;
			};
		}
		&_img{
			flex-basis: 20%;
			margin-right: 2%;
			border-radius: 50%;
			overflow: hidden;
			@include vw($border: 9 solid $brand);
			@include respond-to(small){
				margin: 0 auto 15px;
				flex-basis: 250px;
				height: 250px;
			};
			img{
				width: 100%;
				display: block;
				transform: scale(1.2);

			}
		}
		&_text{
			flex-basis: 70%;
			@include respond-to(small){
				flex-basis: 100%;
				text-align: center;
			};
			h2{
				font-weight: bold;
				font-family: $ff-sec;
				@include vw($font-size: 38,
					$margin-bottom: 10,
					$letter-spacing: 2);
			}
			p{
				font-family: $ff-sec;
				@include vw($margin-bottom: 0,
					$font-size: 28);
				line-height: 1.6;
			}
		}
	}
	.b-niagen{
		@include vw($padding: 140 0);
		@include respond-to(small){
			padding: 50px 0;
			text-align: center;
		};
		@include respond-to(xs){
			padding: 35px 0;
		};
		.g-site_width{
			display: flex;
			justify-content: space-between;
			@include respond-to(small){
				flex-wrap: wrap;
			};
		}
		&_img{
			flex-basis: 37%;
			@include respond-to(small){
				flex-basis: 100%;
			};
			img{
				display: block;
				width: 100%;
				@include respond-to(small){
					max-width: 390px;
					margin: 20px auto 0;
				};
			}
		}
		&_text{
			flex-basis: 58%;
			@include vw($padding-top: 50);
			@include respond-to(small){
				padding-top: 0;
				flex-basis: 100%;
			};
			h2{
				font-family: $ff-sec;
				font-weight: bold;
				@include vw($font-size: 38,
					$margin-bottom: 20);
				span{
					font-family: $ff-sec;
					font-weight: bold;
					display: block;
					@include vw($font-size: 38,
						$margin-bottom: 10);
				}
			}
			p{
				line-height: 1.6;
				@include vw($font-size: 24);
			}
			.b-niagen_btn{
				text-align: right;
				@include vw($margin: 15 auto);
				@include respond-to(small){
					text-align: center;
					margin: 25px 0;
				};
				a{
					display: inline-block;
				}
			}
		}
	}
}