@import "varibles";
/*
==============================
fonts
==============================
*/
@font-face {
    font-family: 'AvenirBlack';
    src: url('/fonts/avenir/AvenirLTStd-Black.otf') format('opentype');
    font-weight: normal;
}
@font-face {
    font-family: 'AvenirBlackOblique';
    src: url('/fonts/avenir/AvenirLTStd-BlackOblique.otf') format('opentype');
    font-weight: normal;
}
@font-face {
    font-family: 'AvenirBook';
    src: url('/fonts/avenir/AvenirLTStd-Book.otf') format('opentype');
    font-weight: normal;
}
@font-face {
    font-family: 'AvenirHeavy';
    src: url('/fonts/avenir/AvenirLTStd-Heavy.otf') format('opentype');
    font-weight: normal;
}
@font-face {
    font-family: 'AvenirLight';
    src: url('/fonts/avenir/AvenirLTStd-Light.otf') format('opentype');
    font-weight: normal;
}
@font-face {
    font-family: 'AvenirMedium';
    src: url('/fonts/avenir/AvenirLTStd-Medium.otf') format('opentype');
    font-weight: normal;
}
body {
	@include vw($font-size: 22);
	font-family: $ff;
	color: #000;
	line-height: 1.25;
	overflow: hidden;
}
main{
	/*@include vw($padding: 155 0 0);*/
	overflow: hidden;
	@include respond-to(600){
		/*padding: 120px 0px 0px;*/
	};
}
p{
	@include vw($font-size: 22,
		$margin-bottom: 20);
	line-height: 1.25;
}
a{
	color: $brand;
	text-decoration: none;
	transition: all 0.3s ease-in;
	&:hover, &:active, &:focus{
		color: $text;
		text-decoration: none;
	}
}
img {
	max-width:100%;
}

sup {
	font-size:60%;
}
.g-site_width{
	@include vw($max-width: 1420,
		$padding: 0 15);
	width: 100%;
    margin: 0 auto;
	@include respond-to(small){
		padding: 0 15px;
	};
	&.resize{
		@include vw($max-width: 1590);
	}
}

/**  Header  **/

.b-overlay{
	display: none;
	position: fixed;
	width: 100%;
	height: 100vh;
background:  #fff;
z-index: 999999;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
.l-title{
	margin-bottom: 5px;
	font-weight: bold;
}
&_input{
	padding: 10px 20px;
	background: #fff;
    text-align: center;
    color: #000;
    width: 100%;
    transition: all 0.5s ease;
	margin-bottom: 15px;
	font-size: 14px;
	border: 2px solid #29abe2;
	@include placeholder{color: rgb(228, 228, 228)};
}
.l-btn{
	padding: 10px 20px;
	background: #29abe2;
    text-align: center;
    color: #fff;
    width: 100%;
    transition: all 0.5s ease;
	margin-bottom: 20px;
	font-size: 14px;
	border: 2px solid #29abe2;
	max-width: none;;
}
}

.g-header {
	background-color: rgb(255, 255, 255);
	@include vw($padding: 0 0 5,
		$border-bottom: 7 solid $brand,
		$min-height: 125);
	position: relative;
	/*position: fixed;*/
	width: 100%;
	top: 0;
	z-index: 9999;
	.g-site_width {
		display: flex;
		justify-content: space-between;
		@include vw(
		$max-width: 1750);
		@include respond-to(small){
			flex-wrap: wrap;
			justify-content: center;
		};
	}
	&_logo {
		@include vw($padding: 0 0 0);
		text-align: center;
		font-family: $ff-sec;
		@include respond-to(small) {
			flex-basis: 100%;
		}
		a {
			color: $grey;
			text-decoration: none;
			line-height: 1;
			display: block;
			font-weight: 500;

			@include vw(
			$font-size: 26,
				$letter-spacing: 16);
			@include respond-to(small) {
				/*font-size: 24px;*/
			}
			img{
				@include vw($max-width: 258,
					$margin-top: 40,
					$margin-bottom: 20);
				@include respond-to(small){
					max-width: 220px;
					margin-top: 28px;
					margin-bottom: 14px;
				};
				@include respond-to(600){
					max-width: 150px;
					margin-top: 18px;
					margin-bottom: 4px;
				};
				@include respond-to(400){
					max-width: 130px;
					margin-top: 10px;
				};
				@include respond-to(330){
					margin: 5px auto 0;
				};
			}

		}
		a:hover, a:focus {
			color: $brand;
			text-decoration: none;
		}
	}
	&_contact{
		flex-basis: 45%;
		flex-grow: 0;
		@include respond-to(small){
			text-align: center;
			flex-basis: 100%;
		};
		.l-contact{
			p{
				@include respond-to(small){
					text-align: center;
					order: 2;
					margin: 0 0 10px;
					font-size: 14px;
				};
				@include respond-to(xs){
					font-size: 10px;
				};
			}
			a{
				@include respond-to(small){
					order: 0;
					flex-basis: 50%;
					text-align: center;
					margin: 10px 0 20px;
					font-size: 22px;
				};
				@include respond-to(600){

					font-size: 15px;
				};
				@include respond-to(400){
					margin: 5px auto 10px;
					font-size: 13px;
				};
				@include respond-to(330){

					font-size: 11px;
				};
			}
		}
	}
}

.g-footer{
	background: rgb(127,127,127);
	color: #fff;
	text-align: left;
	/*@include vw($padding: 20 0 10);*/
	&_title{
		@include vw($font-size: 22,
			$margin: 0 0 7);
	}
	ul{
		list-style: none;
		padding: 0;
		@include vw($margin: 0 0 30);
		@include respond-to(xs){
			line-height: 1;
		};
		li{
			display: inline-block;
			line-height: 1;

			@include vw(
			$border-right: 1px solid #fff);
			&:last-child{
				border: none;
			}
			&:first-child{
				a{
					@include vw(
					$padding: 0 15 0 0);
				}
			}
			a{
				@include vw($font-size: 16,
					$padding: 0 15);
				text-transform: uppercase;
				text-decoration: none;
				color: #fff;
				@include respond-to(xs){
					font-size: 12px;
				};
				&:hover, &:active, &:focus{
					color: #fff;
					text-decoration: underline;
				}
			}

		}
	}
	&_inform{
		@include vw($margin-bottom: 50 !important);
	}
	&_inform, &_copyright{
		@include vw($font-size: 18);
	}
	p{
		@include vw($margin: 0 0 10);
	}
}
