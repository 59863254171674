@import "_functions";

// Базовая ф-ция конвертации в vw
// ex .element { @include vw($font-size: 20, $margin: 10 auto)  }
@mixin vw($args...) {
	@each $property, $value in keywords($args) { 
 /*#{$property}:  map-get(generateVw($value), "px");*/
 }
	@include vw-responsive(keywords($args));
}

@mixin vw-responsive($attributes) {
	@each $property, $value in $attributes {#{$property}:  map-get(generateVw($value, 1), 'vw');}
	@each $width, $multi  in $breakpoints {
		@include respond-to($width) {
			@each $property, $value in $attributes {
				#{$property}:  map-get(generateVw($value, $multi), 'vw');
			}
		}
	}
	
	//Save pixels for mobile devices
	@include respond-to(small) {
		@each $property, $value in $attributes {
			#{$property}:  map-get(generateVw($value, 0.7), 'px');
		}
	}
}

// ex .element{ @include respond-to(small) { ... } }
@mixin respond-to($breakpoint) {
	@if $breakpoint == "small" {
		@media (max-width: 768px) {
			@content;
		}
	}
	@elseif $breakpoint == "xs" {
		@media (max-width: 500px) {
			@content;
		}
	}

	@else {
		@media (max-width: #{$breakpoint}px) {
			@content;
		}
	}
}
@mixin placeholder {
	&::-webkit-input-placeholder {@content}
	&:-moz-placeholder           {@content}
	&::-moz-placeholder          {@content}
	&:-ms-input-placeholder      {@content}  
	
	&:focus::-webkit-input-placeholder {color: transparent}
	&:focus::-moz-placeholder          {color: transparent}
	&:focus:-moz-placeholder           {color: transparent}
	&:focus:-ms-input-placeholder      {color: transparent}
}
