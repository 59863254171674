//Color
$yellow: rgb(253, 191, 45);
$red: rgb(252, 13, 27);
$l-grey: rgb(191, 191, 191);
$brand: #29abe2;
$l-blue: rgb(210, 227, 234);
$text: rgb(74, 74, 98);
$grey: rgb(128, 128, 128);
/*
==============================
Fonts
==============================
*/

$ff: "AvenirBook", sans-serif;
$ff-medium: "AvenirMedium", sans-serif;
$ff-light: "AvenirLight", sans-serif;
$ff-heavy: "AvenirHeavy", sans-serif;
$ff-black: "AvenirBlack", sans-serif;
$ff-sec: 'Open Sans', sans-serif;
