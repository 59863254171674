/***   BASE   ***/
#livechat-compact-container{
	display: none !important;
}
.l-padding{
	@include vw($padding: 80 0);
	@include respond-to(xs){
		padding: 35px 0px;
	};
}
.l-padding_bottom{
	@include vw($padding: 0 0 60);
	@include respond-to(small){
		padding: 0 0 35px;
	};
}
.l-review_btn {
	border: 2px solid #58595b;
	color: #58595b;
	display: inline-block;
	line-height: 1;
	background: transparent;
	text-transform: uppercase;
	@include vw(
	$font-size: 20,
		$padding: 15);
	text-align: center;
}
.l-review_btn:hover, .l-review_btn:focus {
	text-decoration: none;
	background: #585757;
	color: #fff;
}
.l-btn{
	display: block;
	background: transparent;
	color: $brand;
	text-align: center;
	font-family: $ff-sec;
	font-weight: 600;
	width: 100%;
	@include vw($margin: 0 auto,
		$max-width: 250,
		$font-size: 20,
		$padding: 10 10,
		$border: 1.9 solid $brand
		);
	&:hover{
		color: #fff;
		text-decoration: none;
		background: $brand;
	}

	&.yellow{
		background: $yellow;
		color: #000;
		@include vw(
		$border: 1.9 solid $yellow
			);
		&:hover{
			color: #fff;
			text-decoration: none;
			background: $grey;
			@include vw(
			$border: 1.9 solid $grey
				);
		}
	}
	&.orange{
		background: #f7931e;
		color: #fff;
		@include vw($border: 0,
			$font-size: 30,
			$padding: 20 20 12,
			$max-width: 320)
			font-family: $ff;
		&:hover{
			@include vw($border: 0);
			color: #fff;
			text-decoration: none;
			background: $brand;
		}
	}
	&.grey{
		color: rgb(89,89,89);
		@include vw(
		$border: 1.9 solid rgb(89,89,89)
			);
		&:hover{
			color: #fff;
			text-decoration: none;
			background: $grey;
			@include vw(
			$border: 1.9 solid $grey
				);
		}
	}
	&.blue{
		color: #fff;
		background: $brand;
		@include vw(
		$border: 1.9 solid $brand
			);
		&:hover{
			color: #fff;
			text-decoration: none;
			background: $grey;
			@include vw(
			$border: 1.9 solid $grey
				);
		}
	}
	&.lgrey{
		color: $brand;
		background: #e6e6e6;
		@include vw(
		$border: 1.9 solid #e6e6e6
			);
		&:hover{
			/*			color: #fff;
						text-decoration: none;
						background: $brand;
						@include vw(
						$border: 1.9 solid $brand
							);*/
			box-shadow: 0 1.4vw 2.6vw -1.22vw hsla(0,0%,60%,.42), 0 0.4vw 2.3vw 0 rgba(0,0,0,.12), 0 0.8vw 1vw -0.5vw hsla(0,0%,60%,.2);

		}
	}
}

.l-title{
	font-family: $ff-sec;
	color: $brand;
	text-align: center;
	@include vw($font-size: 64,
		$margin-bottom: 30);
	@include respond-to(xs){
		font-size: 28px;
		margin-bottom: 10px;
	};
}
.l-title_bg{
	font-family: $ff-heavy;
	color: #fff;
	text-align: center;
	@include vw($font-size: 22);
	span{
		background: $brand;
		display: inline-block;
		max-width: 100%;
		@include vw($padding: 11 15 5,
			$min-width: 350,
			$margin-bottom: 30);
	}
}
.l-blue_title {
	color: #488fad;
    font-weight: bold;
    line-height: 1.2;
	@include vw(
	$font-size: 27,
		$margin: 0 0 13);
}
.l-grey_subtitle {
	color: #7e7e7e;
    font-weight: bold;
	@include vw(
	$margin: 0 0 6, 	
		$font-size: 18);
}
.l-blue_block {
	background: #d3e3ec;
	color: #3b3838;
	@include vw($font-size: 18);
}
.l-blue{
	color: $brand;
}
.l-hr{
	border: 0;
	@include vw($height: 2,		
		$border-bottom: 1 solid #fff,
		$margin: 10 0);
	background: $text;	
}
.l-descr{
	color: #000;
	font-family: $ff-sec;
	text-align: center;
	@include vw($font-size: 26,
		$margin-bottom: 60);
	@include respond-to(xs){
		font-size: 16px;
		margin-bottom: 20px;
	};
}
/***  Preloader   ***/
/****  preloader   ******/
@-webkit-keyframes animsition-loading{
	0%{
		-webkit-transform:rotate(0deg);
		transform:rotate(0deg)
	}
	to{
		-webkit-transform:rotate(1turn);
		transform:rotate(1turn)
	}
}
@keyframes animsition-loading{
	0%{
		-webkit-transform:rotate(0deg);
		transform:rotate(0deg)
	}
	to{
		-webkit-transform:rotate(1turn);
		transform:rotate(1turn)
	}
}
.l-preload{
	background: #fff;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	z-index: 99999999;
}
.l-load{
	background-color: transparent;
	border-top: 5px solid rgba(0,0,0,.2);
	border-right: 5px solid rgba(0,0,0,.2);
	border-bottom: 5px solid rgba(0,0,0,.2);
	border-left: 5px solid #eee;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-duration: .8s;
	animation-duration: .8s;
	-webkit-animation-name: animsition-loading;
	animation-name: animsition-loading;
	width: 32px;
	height: 32px;
	position: fixed;
	top: 50%;
	left: 50%;
	margin-top: -16px;
	margin-left: -16px;
	border-radius: 50%;
	z-index: 2;
	&:after{
		width: 32px;
		height: 32px;
		position: fixed;
		top: 50%;
		left: 50%;
		margin-top: -16px;
		margin-left: -16px;
		border-radius: 50%;
		z-index: 2;
	}
}
.l-radio{
	display: none;
	&+label{	
		position: relative;
		cursor: pointer;
		@include vw(
		$margin-top: 10,
			$margin-right: 10,
			$padding: 7 35);
		line-height: 1;
		font-weight: bold;
		&:before{
			content:'';
			display: block;
			border-radius: 50%;
			margin: 0 auto;
			position: absolute;
			left: 0;
			@include vw($width: 25,
				$height: 25,
				$border: 1.9 solid $brand,
				$bottom: 8);
		}
		&.error{

			&:before{
				border-color: red;
			}

		}
	}
	&:checked+label{
		&:after{
			content:'';
			display: block;
			border-radius: 50%;
			margin: 0 auto;
			position: absolute;
			background: $brand;

			@include vw($width: 12,
				$height: 12,
				$left: 6,
				$bottom: 15
				);
		}
	}

	&.vertical{
		&+label{	
			@include vw(
			$margin-top: 0,
				$padding: 7 10 30);

			&:before{
				bottom: 0;
				left: 50%;
				@include vw($margin-left: -12.5);
			}
			span{
				/*font-family: $ff-heavy;*/
				font-weight: bold;
				color: $brand;
				display: block;
				@include vw($font-size: 28
					);
			}

		} 
		&:checked+label{
			&:after{
				left: 50%;
				@include vw($bottom: 6,
					$margin-left: -6);
			}
		}
	}

}
/***   Progress Bar  ***/
.l-progress{
	width: 100%;

	@include vw($height: 10,
		$border-radius: 5);
    overflow: hidden;
    background-color: #f5f5f5;
    -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
    box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
	&_wrap{
		display: none;
		@include vw($max-width: 335,
			$margin: 0 auto);
		p{
			font-weight: bold;
		}
	}
	&_bar{
		/*width: 100%;*/
		width: 0%;
		height: 100%;
		animation: loading 2s linear infinite;
		background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
		@include vw($background-size: 40 40);
		background-color: #337ab7;
		transition: width .6s ease;
	}
}
@keyframes loading{
	0% {
		@include vw($background-position: 40 0); 
	}
	100% {
		@include vw($background-position: 0 0); 
	}
}
/***   CONTACT   ***/
.l-contact{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	@include vw($padding: 7 0 0);
	a{
		font-family: $ff-sec;
		color: #000;
		font-weight: 600;
		flex-basis: 48%;
		text-align: right;
		@include vw($font-size: 28);
		@include respond-to(xs){
			font-size: 12px;
		};
		&:nth-child(2){
			text-align: right;
			@include respond-to(600){
				/*text-align: center;*/
			};
		}
		&:hover, &:active, &:focus{
			color: $grey;
			text-decoration: underline;
		}
	}
	p{
		flex-basis: 100%;
		color: $grey;
		font-family: $ff-sec;
		font-weight: 500;
		text-align: right;
		line-height: 1.5;
		@include vw($font-size: 14,
			$margin:  0 0 55);
		@include respond-to(xs){
			/*font-size: 10px;*/
		};
		span{
			display: block;
		}
	}
}

/******   TWITTER BLOCK   *******/

.l-twit{	
	background: url(/images/twit-bg.png) no-repeat; 
	background-size: contain;
	/*background: rgba(125, 203, 236, 0.7);*/
	@include vw($padding: 120 110 120 75 );
	@include respond-to(small){
		background-size: cover;
	};
	@include respond-to(xs){
		padding: 50px 20px;
	};
	&_slider_wrap{
		width: 70%;
		margin: auto;
		text-align: left;
	}
	&_slider{
		padding: 0;
		opacity: 0;
		color: #fff;
		position: relative;
		h3{
			@include vw($font-size:42,
				$margin-bottom:16);
			text-align: left;
			line-height: 1.2;
			font-style: italic;
			@include respond-to(xs){
				font-size: 19px;
			};
		}
		p{
			@include vw($font-size: 28);
			font-family: $ff-sec;
			margin: auto;
			font-weight: 500;
			line-height: 1.6;
			@include respond-to(small){
				width: 94%;
			};
			@include respond-to(xs){
				font-size: 15px;
			};
		}
		li{
			&:before{
				content: '';
			}
		}	
	}
	.bx-controls{
		text-align: center;
		@include vw($margin-top: 20,
			$bottom: 20);
		position: absolute;
		z-index: 9;
		cursor: pointer;
		left: 50%;
		transform: translate(-50%);
		.bx-pager-item{
			display: inline-block;
			.bx-pager-link {
				@include vw($width:17,
					$height:17,
					$margin-left:10,
					$margin-right:10);
				text-indent: -99999px;
				display: block;
				outline: 0;
				background: $grey;
				border-radius: 50%;
				&.active{
					background: $brand;
				}
			}
		}			
	}
	.l-btn{
		@include vw($margin-top: 20);
	}
}

/********  review  ***********/

.l-reviews {
	& {
		@include vw($margin: 70 auto 8);
		float: left;
		width: 100%;

	}
	&_first{
		@include vw($font-size: 30,
			$letter-spacing: 1,
			$margin-bottom: 50);
		font-weight: 600;
		color: $text;
		line-height: 1.2;
		text-align: center;
		span, a{
			font-weight: inherit;
			color: inherit;
			font-size: inherit;
			position: relative;
			text-transform: uppercase;
			text-decoration: none;
		}
	}
	.j-reviews_form_view{
		display: none;
	}
	.l-review_info{
		@include vw(
		$font-size: 30,
			$letter-spacing: 1,
			$margin-bottom: 50);
		font-weight: 600;
		color: $text;
		line-height: 1.2;
		text-align: center;
		span{
			font-weight: inherit;
			color: inherit;
			font-size: inherit;
			position: relative;
			text-transform: uppercase;
			text-decoration: none;
		}
	}
	header {
		background-size: contain;
		/*		h2 {
					@include vw(
					$font-size: 32,
						$margin: 0 auto 13,
						$max-width: 958,
						$padding-left: 280,
						$padding-right: 280);
					@include respond-to(small){
						max-width: 100%;
						padding: 0;
						text-align: center;
					};
				}*/
	}
	&_header {
		background-size: 100%;
		/*		p{
					line-height: 1.6;
					text-align: center;
					@include vw(
					$max-width: 580,
						$margin: 13 auto 0,
						$font-size: 18.62);
				}*/
		p{
			line-height: 1.4;
			@include vw(
			$max-width: 1200,
				$margin: 13 auto 0);
		}
		&_descr{
			@include vw($margin:40 auto 45,
				$max-width: 675);
			display: flex;
			flex-wrap: wrap;   
			justify-content: flex-start;
			&_top{
				flex-basis: 100%;
				@include vw($font-size: 18.62,
					$margin-bottom: 10);
				@include respond-to(small){
					text-align: center;
				};
				sup{
					color: red;
					font-weight: bold;
				}
			}
			ul{
				list-style: none;
				padding: 0;
				@include vw($margin-bottom:40,
					$flex-basis:240,
					$margin-right:60);
				@include respond-to(small){
					margin-bottom: 28px;
					flex-basis: 160px;
					margin: 0 auto 30px;
				};
				li{
					@include vw($font-size:17,
						$margin-bottom: 5); 
					display: flex;
					justify-content: space-between;
					align-items: center;
					cursor: pointer;
					&:before{
						content: '';
					}
					.l-reviews_header_name{
						text-decoration: underline;
						white-space: nowrap;
						@include vw($margin-right:10);
					}
					span{
						display: inline-block;
						width: 100%;
						cursor: pointer;
					}
					.jq-ry-container{
						cursor: pointer !important;
					}
					img{
						@include vw($width:108);
						vertical-align: middle;
					}
					&:hover{
						cursor: pointer;
						span{
							text-decoration: none;
						}
					}
				}
			}
			&_share{
				@include vw($flex-basis:360);
				text-align: center;
				@include respond-to(small){
					margin: 0 auto;
				};
				span{
					@include vw($margin-bottom:25,
						$font-size: 18.62);
					display: block;
					text-align: center;
				}
				.l-btn{
					@include vw($font-size:18.84,
						$max-width:340,
						$padding: 7 13);
					width: 100%;
					margin: auto;
					display: block;
				}
			}
		}
	}
	&_container{
		.j-package_preloader{
			@include vw($margin: 20 auto);
		}
	}
	&_row {
		& {
			background: #f9f9f9;
			width: 100%;
			@include vw(
			$padding: 30 25,
				$margin: 0 0 17);
		}
		&_header {
			& {
				@include vw($margin: 0 0 15);
				display: flex;
				flex-wrap: wrap;
			}

			span {

				color: #3b3838;
				line-height: 1;
				display: flex;
				align-items: center;
				text-align: center;
				justify-content: center;
				@include vw(
				$font-size: 22,
					$margin-right: 15,
					$min-height: 50);
			}
			&_name {
				@include vw(
				$border: 1.9 solid #0f0f0f,
					$min-width: 235);
			}
			&_star {
				@include vw(
				$min-width: 195);
			}
			img {
				@include vw($width: 117);
				display: inline-block;
				vertical-align: middle;
			}
		}

		p {
			@include vw($font-size: 20);
			line-height: 1.6;
			margin: 0;
		}
		&_answer {
			@include vw($padding: 20 0 0 50);
			span {
				@include vw(font-size, 21);
				line-height: 1.5;
				color: $brand;
				font-weight: bold;
			}
			a {
				color: #bright;
				text-decoration: underline;
			}
			a:hover, a:focus {
				text-decoration: none;
			}
		}
	}
	&_btns{
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;

		a, button{
			max-width: 100%;
			@include vw($margin: 7 20 ,
				$width: 350);
		}
	}
	footer{
		text-align: center;
		@include vw($margin: 25 0 0);
		.l-btn{
			@include vw($font-size:18.84,
				$max-width:340,
				$padding: 13);
			width: 100%;		
		}
	}
	&_form{
		@include vw($margin: 50 auto 30);

		h3{

		}
		&_rating{
			display: flex;
			align-items: center;
			@include vw($margin: 30 0);
			&_overall{
				font-weight: bold;
			}
			&_details{
				color: $brand;
				font-weight: bold;
			}
		}
		&_item{
			@include vw($padding: 0 30  0 0);
		}
		.form-group{
			@include vw($margin-bottom: 20);
			label{
				font-weight: bold;
				@include vw($font-size: 18,
					$margin-bottom: 5);
			}
			.form-control {
				@include vw($height: 48,
					$padding: 7 10,
					$font-size: 18,
					$border: 1.5 solid #ccc,
					$border-radius: 5);
			}
			textarea.form-control{
				@include vw($height: 180);
			}
		}
		&_checkbox{
			.checkbox{
				@include vw($margin: 30 0 20);
				label{
					@include vw($padding-left: 30,
						$margin-bottom: 0);
					min-height: 0;
					font-weight: 400;
					cursor: pointer;
					input{
						margin-top: 0;
						@include vw($margin-left: -30,
							$width: 30,
							$height: 30);
					}
				}
			}
		}
		&_request{
			ul{
				li{
					@include vw($font-size: 20);
				}
			}
		}
		&_btn{
			text-align: center;
		}
	}
	.j-review_hidden {
		display: none;
	}
	.j-page_preloader {
		background: url(/images/system/page_preloader.gif) no-repeat;
		width: 16px;
		height: 11px;
		text-align: center;
		@include vw($margin: 20 auto);
	}

}

/***  Modal  ****/
.l-modal{

	.modal-dialog{
		@include vw(
		$width: 1000,
			$margin: 130 auto 0);
		max-width: 94%;

	}
	.modal-body {
		@include vw($padding: 50 30 30 );
		text-align: center;
	}
	button.close {
		position: absolute;
		@include vw($right: 20);
		@include vw($top: 15);
		@include vw($font-size: 30);
		z-index: 99;
		color: $brand;
		opacity: 1;
		&:hover{
			color: #000;
			opacity: 0.7;
		}
	}
	&_title{
		color: $brand;
		text-align: center;
		font-weight: bold;
		line-height: 1.4;
		text-align: center;
		@include vw(
		$padding: 5 30 7,
			$font-size: 35,
			$margin: 0 0 10);
	}
	&_text{
		text-align: left;
	}
	&_tabs{
		border: none;
		display: flex;
		justify-content: space-between;
		@include vw($margin-bottom: 40);
		&:before, &:after{
			display: none;
		}
		li{
			background: $brand;
			color: #fff;
			text-align: center;
			font-family: $ff-sec;
			font-weight: 600;
			width: 24%;
			transition: all 0.3s ease-out;
			border: none;
			@include vw(
			$margin: 0 auto,
				$max-width: 250,
				$font-size: 20
				);
			&:hover, &:active, &:focus{
				color: $text;
				text-decoration: none;
				background: $yellow;
				border: none;
				@include vw(
				);
			}
			&.active{
				background: $yellow;
				border: none;
				a{
					display: block;
					color: $text;
					cursor: default;
					border: none;
					background: $yellow;
					@include vw($margin-top: -5);
					&:hover, &:active, &:focus{
						border: none;
						background: $yellow;
						color: $text;
						@include vw($margin-top: -5);
					}
				}	
			}
			a{
				display: block;
				color: #fff;
				cursor: pointer;
				background-color: transparent;
				border: none;
				border-radius: 0;
				margin: 0 auto;
				@include vw($padding: 7 10);
				&:hover{
					display: block;
					/*color: $text;*/
					cursor: pointer;
					background-color: transparent;
					border: none;
					border-bottom-color: transparent;
					border: none;
					background: $yellow;
					color: $text;
				}
			}
		}
	}
	p{
		@include vw($font-size: 22);
		@include vw($margin-bottom: 15);

	}

	&_contact{
		.b-left, .b-right{
			float: left;
			width: 50%;
			@include respond-to(small){
				float: none;
				width: 100%;
				margin-bottom: 15px;
			};
			span{
				display: block;
				@include vw($font-size: 26,
					$margin-bottom: 10);
				color: $brand;
				font-weight: bold;
			}
			a{
				font-size: inherit;
			}
		}
	}
}

/*********  supplement table  *********/
.l-product_supplements {

	width: 100%;
	@include vw($min-width: 420,
		$border: 3 solid #3b3838);
	@include respond-to(small){
		min-width: 340px;
	};
	tbody{
		tr {
			border-top: 1px solid;
		}
		.l-product_supplements_header{
			th {
				@include vw($font-size: 34);
			}
			td {
				@include vw($border-top: 4 solid #3b3838,
					$border-bottom: 0 solid #3b3838);
			}
		}


		tr td {
			text-align: right;
			@include vw($padding: 5 10);
			&.r-padding {
				@include vw($padding-left: 10);
			}
			&:first-child{
				text-align: left;
			}
			&:nth-child(2){
				white-space: nowrap;
			}
		}
		tr:first-child  td:first-child {
			text-align: right;
			@include vw($padding: 5 6);
		}

	}
	.l-product_supplements_note {
		@include vw($font-size: 16);
		td {
			border-bottom: medium none;
			border-top: medium none;
			&:first-child {
				@include vw($font-size: 16);
				@include vw($padding-left: 20);
				padding-top: 0;
			}
		}
	}
	tfoot{
		tr td {
			@include vw($border-top: 4 solid #3b3838);
			text-align: left;
		}
		td {
			@include vw($padding: 5 5);
		}
	}

	th {
		@include vw($font-size: 18,
			$padding: 3 5);
		line-height: 1.3;
		/*padding: 3px 5px;*/
	}
	td {
		@include vw($font-size: 18,
			$padding: 3 5);
		line-height: 1.3;

	}
	&_title th {
		@include vw($font-size: 27);
	}
	&_title_row th {
		font-weight: normal;
		padding-top: 0;
	}
	&_last_col{
		@include vw($min-width: 160);
	}
	&_footer{
		text-align: left;
		@include vw($font-size: 18);
	}
	.l-border_bold{
		@include vw($border-top: 4px solid);
	}
	.l-border_none{
		border-top: none;
		td{
			@include vw($padding: 2 10);
		}
	}
	.l-bottom_padding{
		td{

			@include vw($padding-bottom: 2);
		}
	}
	.l-padding{
		@include vw($padding-left: 20);
	}
	.l-sublist td {
		@include vw($padding-left: 30);
	}


}

/*********  modal supplement  ***********/
.l-modal_suppl{

	.l-supplements{
		@include vw($max-width: 650);
		margin: auto;
		.l-product_supplements{
			&_footer {
				@include vw($font-size: 14);
			}
		}
	}

}

/*********  modal scroll  ***********/
.l-modal_scroll{
	.modal-content{
		max-height: 85vh;
		overflow: auto;		
	}
	.modal-body{
		text-align: left;	
	}
	&_header{
		@include vw($border-bottom: 1.5 solid $l-grey,
			$margin-bottom: 40);
		img{
			@include vw($width: 340);
		}
	}
	&_body{
		overflow-y: auto;
		max-height: 50vh;
		height: 50vh;
		.jspContainer{
			/*@include vw($padding-right: 200 !important);*/
		}
		.jspPane{
			@include vw($padding-right: 60 !important);
			/*width: 100% !important;*/
		}
		.jspVerticalBar {
			@include vw($width: 7,
				$right: 20);
			background: $brand;
			.jspTrack{
				background: $brand;
			}
		}
		.jspDrag{
			@include vw($width: 30  !important,
				$height: 30  !important,
				$left: -12);
			background: red;
			position: absolute;
			background: url(/images/scroll_logo.jpg) center center;
			background-size: cover;
			border-radius: 50%;
		}

	}
}
/*********  modal chromadex  ***********/
.l-modal_chromadex{
	&_header{
		@include vw($margin-bottom: 50);
		h2{
			font-family: $ff-heavy;
			color: rgb(4, 58,89);
			@include vw($font-size: 30,
				$margin-bottom: 15);
		}
		h3{
			font-family: $ff-heavy;
			color: #000;
			@include vw($margin-bottom: 15
				);
		}
		p{
			font-style: italic;
			font-weight: bold;
			@include vw($font-size: 22);
		}
	}
	h3{
		font-family: $ff-heavy;
		@include vw($margin-bottom: 15);
	}
	p{
		a{
			text-decoration: underline;
			color: #000;
			&:hover{
				text-decoration: none;
			}
		}
	}
	&_footer{
		&_item{
			@include vw($margin-bottom: 20);
			span{
				font-family: $ff-heavy;
			}
			a{
				text-decoration: none;
				color: #000;
				&:hover{
					text-decoration: underline;
				}
			}
		}
		p{
			@include vw($margin: 0 0 15);
			span{
				font-family: $ff-heavy;
			}
		}
	}
}

/*********  modal steps  ***********/
.l-modal_steps{
	.l-title_bg{
		@include vw($margin-bottom: 50);
		span{
			background: $yellow;
			color: #000;
			@include vw($padding: 17 15 10 ,
				$margin-bottom: 0);
		}
	}
	h4{
		color: $brand;
		font-family: $ff-heavy;
		@include vw($font-size: 24);
	}
	.l-btn{
		opacity: 1;
		float: none;
		text-shadow: none;
		line-height: 1.4;
	}
}

p.l-disclaimer{
	@include vw($font-size: 16 !important,
		$margin: 10 auto !important);
	text-align: center;
	color: $text !important;


}
.l-description_blue{
	background: $l-blue;
	@include vw($margin: 40 0,
		$padding: 20 0);
	p{
		@include vw($font-size: 20);
	}
}
.l-product_description{
	section{
		@include vw($padding: 20);
	}
	h2{
		color: $brand;
		text-align: center;
		font-weight: bold;
		@include vw($margin: 20 0,
			$font-size: 32
			);
	}
	p{
		@include vw($font-size: 20,
			$margin-bottom: 20);
	}
	.l-supplements{
		.table-responsive{
			@include vw($width: 550);
			margin: auto;
		}
	}
}
.l-film{
	text-align: center;
	//@include background-image(linear-gradient(to bottom , white, $l-blue));
	@include vw($padding: 20 0 80);
	h2{
		color: $brand;
		font-weight: bold;
		@include vw($font-size: 32,
			$margin: 20 0);
	}
	&_video{
		position: relative;
		width: 80%;
		margin: auto;
		padding-bottom: 45%;
		@include vw($margin-bottom: 40,
			$border: 2 solid #eee);
		iframe{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		&_overlay{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: #fff;
			display: flex;
			margin: auto;
			cursor: pointer;
			&:hover .l-start_video{
				background: $grey;

			}
			p{
				margin: auto;
				@include vw($font-size: 36);
			}
		}
		.l-start_video{
			@include vw($font-size: 20,
				$padding: 10 15 );
		}

	}
}
.l-benefit{
	&_left{
		float: left;
		width: 40%;
		margin-left: 10%;
		h3{
			color: $brand;
			font-weight: bold;
			@include vw($font-size: 34,
				$margin-bottom: 20);
		}
		@include respond-to(small){
			float: none;
			width: 100%;
			margin: 0 auto;
			text-align: center;
			max-width: 300px;
		};
		ul{
			list-style: disc;
			li{
				list-style: disc;
				@include vw($font-size: 20);
				@include respond-to(small){
					text-align: left;
				};
			}
		}
	}
	&_right{
		float: right;
		width: 48%;
		@include respond-to(small){
			float: none;
			width: 100%;
			text-align: center;
			margin: 20px auto;
			width: 259px;
		};
	}
	.l-slider .carousel-wrapper,
	.l-slider .l-carousel{
		@include vw($height: 360);
	}
	.l-slider .l-carousel .cloud9-item{
		@include vw($width: 180);
	}
}
/*
=================================================================
	modal bottle (slider)
=================================================================
*/
.l-modal_slider{
	.modal-dialog{
		width: 750px;
		@include vw($width: 750);
	}
	/*	.close{
			position: absolute;
			width: 30px;
			height: 70px;
			right: 25px;
			top: 5px;
			z-index: 9999;
			font-size: 60px;
		}*/
	.b-bottle_slider_wrap{
		width: 90%;
		text-align: center;
		position: relative;
		@include vw($margin: 20 auto);
		&.opacity{
			opacity: 0;
		}
	}
	ul{
		list-style: none;
		li{
			list-style: none;
			&:before{
				content: '';
			}
		}
	}
	img{
		width: 100%;
		@include vw($max-width: 500);
	}
	.bx-prev, .bx-next{
		@include vw($width: 70,
			$height: 140,
			$margin-top: -70);
		position: absolute;
		top: 50%;
		z-index: 999;
	}
	.bx-prev{
		background: url(/images/slider_arrow_left.png) no-repeat center;
		background-size: cover;
		left: -5%;
	}
	.bx-next{
		background: url(/images/slider_arrow_right.png) no-repeat center;
		background-size: cover;
		right: -5%;
	}
}
/*
=================================================================
	slider (bottles)
=================================================================
*/

/********  bottle slider **********/
.l-slider{

	@include vw($max-width: 500,
		$margin: 0 auto 0);
	.carousel-wrapper {
		width: 100%;
		margin: 0 auto;
		float: none;
		position: relative;
		@include vw($height: 310);
		@include respond-to(small){
			height: 252px;
		};
	}
	.l-carousel {
		@include vw($height: 270);
		display: none;
		overflow: visible !important;
		text-align: center;
		@include respond-to(small){
			height: 252px;
		};
		.cloud9-item {
			@include vw($width: 150);
			position: relative;
			@include respond-to(small){
				width: 126px;
			};
		}
	}
	.l-carousel img {
		width: 100%;
		opacity: 0;
	}
	.buttons {
		text-align: center;
		cursor: pointer;
		@include respond-to(small){
			position: absolute;
			width: 100%;
			top:50%;
			margin-top: -18.55px;
		};
	}
	.buttons > div {
		display: inline-block;
		vertical-align: middle;
		@include vw($width: 44,
			$height: 53,
			$margin: -26.5 5 0);
		position: absolute;
		top: 50%;
		@include respond-to(small){
			margin: 0;
			top: 0;
		};
		img{
			width: 100%;
		}
	}
	.buttons .right {
		background: url(/images/slider_arrow_right.png) no-repeat center; 
		/*right: -4%;*/
		@include vw($right: -44);
		z-index: 9;
		background-size: cover;
		@include respond-to(small){
			/*right: -25%;*/
		};
		@include respond-to(xs){
			/*right: -15%;*/
		};
	}

	.buttons .left {
		background: url(/images/slider_arrow_left.png) no-repeat center; 
		/*left: -5%;*/
		@include vw($left: -44);
		z-index: 9;
		background-size: cover;
		@include respond-to(small){
			/*left: -25%;*/
		};
		@include respond-to(xs){
			/*left: -15%;*/
		};
	}
}
/*
=================================================================
	right slider 
=================================================================
*/
.l-right_slider{
	width: 25%;
	margin: 0 28%;
	float: right;
	@include vw($margin-bottom: -95);
	@include respond-to(small){
		width: 100%;
		margin: 0 auto 0px;
		max-width: 100%;
		float: none;
		margin: 20px auto;
		width: 259px;
	};
	.l-slider .carousel-wrapper,
	.l-slider .l-carousel{
		@include vw($height: 360);
	}
	.l-slider .l-carousel .cloud9-item{
		@include vw($width: 180);
	}
}

/*
=================================
	More question
=================================
*/
.l-more_question {
	& {
		@include vw($margin: 31 0 0);
	}
	.g-site_width {
		display: flex;
		justify-content: space-between;
		@include respond-to(xs){
			flex-wrap: wrap;
			justify-content: center;
		};
	}
}
.c-more_question_left {
	& {
		width: 37%;
		@include respond-to(xs){
			width: auto;
			flex-basis: 300px;
		};
	}
	img {
		width: 100%;
	}
}
.c-more_question_right {
	& {
		@include vw($margin: 5 0 0);
		width: 58%;
		@include respond-to(small){
			margin: 30px 0px 0px;
			flex-basis: 100%;
			text-align: center;
		};
	}
	.l-blue_block {
		font-weight: bold;
		text-align: center;
		@include vw(
		$font-size: 28,
			$margin: 0 0 12,
			$padding: 7 10);
	}
	p {
		@include vw($font-size: 18);
		line-height: 1.6;
	}
	.livechat_button a{
		background: #498eaa;
		color: #fff;
		display: inline-block;
		font-weight: bold;
		@include vw($font-size: 22,
			$padding: 10 15);
		&:hover, &:active, &:focus{
			background: #58595b;
			text-decoration: none;	
		}
	}
}

/*
=================================
		References
=================================
*/
.l-references {
	& {
		color: #000;
		background: #f9f9f9;
		@include vw(
		$padding: 20 0,
			$margin: 63 0 0);
	}
	h5 {
		color: #488fad;
		font-weight: bold;
		@include vw($font-size: 20,
			$margin-bottom: 20);
	}
	p, li {
		@include vw(
		$font-size: 16,
			$margin: 0 0 10);
	}
	li {
		list-style-type: decimal;
		list-style-position: inside;
	}
	a {
		color: #488fad;
	}
}
/*** ORDER ***/
.b-order{
	/*background: linear-gradient(180deg, $l-blue 0%, #fff 20%);*/
	background: #f2f2f2;
	&_bottles{
		display: none;
		flex-basis: 100%;
		@include respond-to(small){
			display: block;
			text-align: center;
			img{
				width: 60%;
			}
		};
		@include respond-to(xs){
			img{
				width: 80%;
			}
		};
	}
	&_header{
		text-align: center;

		@include vw($margin-bottom: 40);
		span{
			display: block;
			font-family: $ff-sec;
			font-weight: 400;
			@include vw($font-size: 21,
				$margin: 10 auto);
		}
		&_recommend{
			@include vw($font-size: 38,
				$margin-bottom: 20);
			font-family: $ff-medium;
			color: $brand;
			text-align: center;
			display: none;
		}
	}
	&_container{
		display: flex;
		justify-content: space-between;
		@include respond-to(small){
			flex-wrap: wrap;
		};
	}

	/* Faq section */
	&_left{
		flex-basis: 26%;
		flex-grow: 0;
		@include respond-to(small){
			flex-basis: 100%;
			order: 1;
			z-index: 9;
			position: relative;
			margin-top: 60px;
		};
	}
	&_faq{
		@include vw($margin-bottom: 20);
		&_title{
			color: $text;
			font-family: $ff-sec;
			font-weight: 600;
			@include vw($font-size: 22,
				$margin-bottom: 30);
		}
		h4{
			font-family: $ff-medium;
			@include vw($font-size: 16,
				$margin-bottom: 10);
		}
		p{
			@include vw($font-size: 16,
				$margin-bottom: 25);
			line-height: 1.45;
		}
	}
	&_inform{
		@include vw($margin: 0 );
		max-width: 100%;
		@include vw($width: 340);
		@include respond-to(small){
			margin: 20px auto 0;
		};
		h3{
			color: $text;
			font-family: $ff-sec;
			font-weight: 600;
			text-decoration: underline;
			text-align: center;
			@include vw($font-size: 24,
				$margin-bottom: 20);
		}
		ul{
			li{
				a{
					display: block;
					font-weight: 600;
					font-family: $ff;
					background: #e6e6e6;
					color: $brand;
					text-align: center;
					@include vw($padding: 14 10 12,
						$font-size: 21,
						$margin-bottom: 11);
					&:hover{
						color: #fff;
						background: $brand;

					}
				}
				/*					&:nth-of-type(even){
										a{
											background: $brand;
											color: #fff;
											&:hover, &:active, &:focus{
												color: #fff;
												background: $l-grey;
				
											}
										}
									}*/
			}
		}
	}
	/* end Faq section */

	&_right{
		flex-basis: 73%;
		flex-grow: 0;
		@include respond-to(small){
			flex-basis: 100%;
			order: 0;
			overflow: hidden;
		};
	}
	&_slider{
		/*overflow: hidden;*/
		position: relative;
		height: 100%;
	}
	&_slide{
		display: flex;
		justify-content: space-between;
		position: absolute;
		width: 100%;
		z-index: 1;
		&.first{
			@include respond-to(small){
				position: relative;
				flex-wrap: wrap;
			};
		}
		&.second{
			z-index: 0;
			opacity: 0;
			/*display: none;*/
			@include respond-to(small){
				flex-wrap: wrap;
			};

		}
		.r-title, .r-header, .r-recommend_title, .r-recommend_qwt{
			display: none;
		}

		&.r-recommend{
			.b-order_options{

				background: linear-gradient(0deg, #fff 0%, #f3f3f3 60%,  #d2d2d2 100%);
			}
			.b-order_options_header{
				span{
					display: none;
				}
			}
			.r-header{
				display: block;
				color: $text;
				font-family: $ff-sec;
				font-weight: 600;
				text-decoration: underline;
				text-align: center;
				@include vw($font-size: 24,
					$margin : 20 0);
			}
			.b-order_options_wrap{
				@include vw($padding: 0 25);
			}
			.b-order_chosen_title,
			.b-order_chosen_header{
				display: none;
			}
			.r-recommend_title{
				display: block;
				background: $brand;
				color: #fff;
				font-family: $ff-medium;
				line-height: 1;
				/*text-transform: uppercase;*/
				@include vw($margin-bottom:10,
					$padding: 8 0 3);
				&.green{
					background: rgb(26, 175, 84);
				}
			}
			.r-recommend_qwt{
				display: block;
				color: $text;
				font-family: $ff-sec;
				font-weight: 600;
				@include vw($font-size: 20,
					$margin-bottom: 25);
				&.bigger{
					@include vw($font-size: 22);
				}
			}
		}
	}

	&_options{
		flex-basis: 55%;
		flex-grow: 0;
		@include respond-to(small){
			order: 1;
			flex-basis: 100%;
		};
		&_wrap{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			@include vw($padding: 0 7);
		}
		&_header{
			flex-basis: 100%;
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			@include vw($padding-bottom: 10);
			span{
				@include vw($font-size: 22);
				color: $brand;
				font-family: $ff-sec;
				font-weight: 600;
				line-height: 1;
			}
			&_img{
				display: flex;
				align-items: flex-end;
				img{
					max-width: 100%;
					@include vw($width: 50);
				}
			}
		}

		&_item{
			flex-basis: 48%;
			flex-grow: 0;
			text-align: center;
			background: #f2f2f2;
			@include vw($border: 9 solid $brand,
				$padding: 10 0,
				$margin-bottom: 20);
			@include respond-to(xs){
				margin-bottom: 10px;
				flex-basis: 49%;
			};
			&:hover, &:active, &:focus{
				box-shadow: 0 1.4vw 2.6vw -1.22vw hsla(0,0%,60%,.42), 0 0.4vw 2.3vw 0 rgba(0,0,0,.12), 0 0.8vw 1vw -0.5vw hsla(0,0%,60%,.2);
			}
			&.best{
				@include vw($padding-top: 0);
				.b-order_options_line{
					background: $yellow;
					color: #000;
					font-family: $ff-sec;
					@include vw($margin:  0 0,
						$padding: 7 0 8,
						$font-size: 20);
				}
				.b-order_options_img{
					@include vw($margin: 19 0 0 );
					@include respond-to(xs){
						margin: 5px 0 0;
					};
				}
				.b-order_options_title{
					color: #000;
					@include vw($margin: 16 0 0 );
				}
				.b-order_options_descr{

					color: $red;
					@include vw($margin-bottom: 10);
				}
				.b-order_options_dose{
					@include vw($margin-bottom: 0);
				}
			}
		}
		&_title{
			font-family: $ff-sec;
			color: #000;
			@include vw($font-size: 22,
				$margin-bottom: 8);
		}
		&_descr{
			/*background: $brand;*/
			color: $red;
			font-family: $ff-heavy;
			font-weight: 600;
			@include vw($font-size: 18.62,
				$padding: 5 10,
				$margin-bottom: 10);
			&.r-font{
				color: $red;
				font-family: 'Oswald', sans-serif;
				@include vw($letter-spacing: 1);
			}
		}
		&_dose{
			font-family: $ff-heavy;
			color: #000;
			@include vw($font-size: 18.62,
				$margin-bottom: 16);
			@include respond-to(xs){
				font-size: 12px;
			};
		}
		&_img{
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			@include vw($margin: 30 0 25 );
			@include respond-to(xs){
				margin: 15px 0 10px;
			};
			span{
				font-weight: 600;
				font-family: $ff-sec;
				color: $grey;
				@include vw($font-size: 24,
					$margin: 0 0 0 15);
			}
			img{
				@include vw($width: 120);
			}
		}
		&_reorder{
			color: $text;
			font-weight: 600;
			font-family: $ff-sec;
			text-align: center;
			@include vw($font-size: 22);
			span{
				font-weight: inherit;
			}
			a{
				color: $text;
				text-decoration: underline;
				font-weight: inherit;
				&:hover, &:active, &:focus{
					color: $text;
					text-decoration: none;	
				}
			}
		}

		.l-contact{
			@include vw($margin-top: 10);
			a{
				@include vw($font-size: 23);
				@include respond-to(xs){
					font-size: 14px;
					text-align: center;
				};
			}
		}
	}
	&_recommend{
		flex-basis: 41%;
		background: #92d0ea;
		text-align: center;
		@include vw($margin:  0 0,
			$padding: 30 );

		@include respond-to(small){
			flex-basis: 100%;
			order: 1;
			margin-top: 30px;

		};
		&_wrap{
			&.reorder{
				display: none;
			}
		}
		h2{
			color: #fff;
			font-family: $ff-sec;
			text-align: center;
			line-height: 1.4;
			@include vw($font-size: 48,
				$margin-top: 20,
				$letter-spacing: 1);
		}
		&_descr{
			line-height: 1.5;
			@include vw($margin: 55 0 0,
				$font-size: 30,
				$padding: 0);
		}
		&_taken{
			@include vw($padding: 30 0 0);
			height: 0;
			overflow: hidden;
			transition: all 0.5s ease-out;
			p{
				color: $brand;
				font-family: $ff-sec;
				font-weight: 600;
				@include vw($font-size: 24);
			}
			img{
				max-width: 100%;
				@include vw($width: 100);
			}
		}
		&_btn{

			@include vw($margin: 17 auto 40 auto );
			a{
				max-width: 100%;
				@include vw($letter-spacing: 2 );
			}
		}
	}
	&_bottle{
		flex-basis: 43%;
		text-align: center;
		@include vw($margin: 0 0 0,
			$padding: 0 10 30);

		@include respond-to(small){
			flex-basis: 100%;
			margin-bottom: 40px;
		};
		a{
			display: block;
			text-align: left;
			@include vw($margin-bottom: 15);
			span{
				background: $brand;
				color: #fff;
				text-transform: uppercase;
				@include vw($padding: 8 5 4);
			}
		}
		img{
			width: 65%;
			@include respond-to(small){
				max-width: 100%;
				width: 140px;
			};
		}
		p{
			color: $brand;
			font-family: $ff-heavy;
			@include respond-to(small){
				display: none;
			};
		}
	}
	&_chosen{
		position: relative;
		.b-prestep{
			background: #cae4ef;
			background: rgb(160, 208, 232 );
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 101%;
			text-align: center;
			color: #fff;
			z-index: 9999;
			display: none;
			@include vw($padding: 30 20);
			&.accessed{
				visibility: hidden !important;
			}
			&_title{
				font-family: $ff-black;

				@include vw($font-size: 60);
				@include respond-to(515){
					font-size: 32px;
					margin-bottom: 0px;
				};
				@include respond-to(415){
					font-size: 30px;
					margin-bottom: 0px;
				};
			}
			&_content{
				@include vw($margin: 10 0 30);
				@include respond-to(small){
					width: 100%;
					max-width: 500px;
					margin: 10px auto 30px;
					font-size: 18px;
				};
				@include respond-to(515){
					margin: 5px 0 15px;
					font-size: 16px;
				};
				@include respond-to(415){
					margin: 5px 0 15px;
					font-size: 16px;
				};
			}
			&_btns{
				display: flex;
				justify-content: space-around;
				position: relative;
				@include vw($margin: 0 0 20);
				&_bg{
					background:  $text;
					background:  $brand;
					position: absolute;
					left: 0;
					height: 110%;
					width: 0%;
					transition: all 0.5s ease;
					z-index: 9;
				}
				
				.b-prestep_btn{
					position: relative;
					background: #e6e6e6;
					color: $text;
					font-family: 'Oswald', sans-serif;
					transition: all 0.5s ease;
					box-shadow: 0 0.2vw 0.2vw 0 hsla(0,0%,60%,.14), 0 0.3vw 0.1vw -0.2vw hsla(0,0%,60%,.2), 0 0.1vw 0.5vw 0 hsla(0,0%,60%,.12);
					@include vw($padding: 10 30);
					a{
						color: inherit;
						display: block;
						text-transform: uppercase;
					}
					@include respond-to(small){
						font-size: 20px;
					};
					&:hover{
						color: $brand;
						a{
							color: $brand;
						}
					}
				}
				.b-prestep_phone{
					display: none;
					width: 80%;
				}
				&_arrow{
					position: absolute;
					z-index: 99;
					cursor: pointer;
					width: 0;
					height: 0;
					top: 50%;
					transform: translateY(-50%);
					@include vw($border-top: 15 solid transparent,
						$border-right: 25 solid $brand,
						$border-bottom: 15 solid transparent,
						$left: 15);
				}
			}
			form{
				display: block;
				margin: 0;
				.b-order_reveal_input_wrap{
					width: 100%;
					margin: 0 auto;
					@include vw($max-width: 500)
						@include respond-to(small){
						font-size: 20px;
						max-width: 600px;
					};
					input{
						@include respond-to(small){
							padding: 10px 10px 5px;
						};
					}
				}
				a{
					display: block;
					@include vw($max-width: 400,
						$margin-top: 20);
					@include respond-to(small){
						font-size: 20px;
						width: 100%;
						max-width: 390px;
					};
				}
			}
		}
		.b-order_options{
			&_item{
				@include vw($padding: 0 0 15);
				cursor: default;
				transform: rotateX(90deg);
				transition: all 0.5s ease-out;
				.l-btn{
					max-width: 80%;			
				}
			}
			&_header{
				justify-content:  center;
			}

		}
		&_title{
			flex-basis: 100%;
			font-family: $ff-heavy;
			@include vw($padding-bottom: 10,
				$font-size: 18,
				$margin: 20 0 0);
			line-height: 1;
			span{
				display: block;
				font-weight: 600;
				@include vw($margin: 7,
					$font-size: 22);
			}
		}
		&_price{
			color: $brand;
			font-family: $ff-heavy;
			position: relative;
			line-height: 0.75;
			@include vw($font-size: 54,
				$margin: 10 0 );
			&_ea{
				@include vw($font-size: 20);
			}
			&_cent{
				@include vw($font-size: 20);
				top: 0;
				position: absolute;
				line-height: 1;
				text-decoration: underline;
			}
			&_sub{
				@include vw($font-size: 16);
				position: absolute;
				bottom: 0;
				line-height: 1;
			}
		}
		&_total{
			@include vw($font-size: 16,
				$margin: 3 0 0);
			color: $brand;
			font-family: $ff-heavy;
		}
		&_header{
			background: $brand;
			color: #fff;
			font-family: $ff-medium;
			@include vw($margin-bottom:0);
		}

	}
	&_reveal{
		p{
			line-height: 1.3;
			text-align: center;
			@include vw($margin-bottom: 5,
				$font-size: 18);
		}
		&_contact{
			color: $brand;
		}
		form{
			@include vw($margin-top: 20);
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		&_input{
			&_wrap{
				flex-basis: 55%;
				text-align: center;
			}
			transition: all 0.5s ease-out;
			@include vw(
			$border: 2 solid $brand,
				$border-radius: 30);

			input{
				background: $brand;
				text-align: center;
				color: #fff;
				width: 100%;
				transition: all 0.5s ease;
				@include vw($padding: 10,
					$border: 2 solid #fff,
					$border-radius: 30);
				@include placeholder(){
					color: #fff;

				};
			}


		}
		&_btn{
			flex-basis: 41%;
			color: $brand;
			background: #e6e6e6;
			box-shadow: 0 0.3vw 0.3vw -1vw hsla(0,0%,60%,.62), 0 0.2vw 0.2vw 0 rgba(0,0,0,.32), 0 0.3vw 0.3vw -0.2vw hsla(0,0%,60%,.4);
			color: $brand;
			text-align: center;
			width: 100%;
			@include vw($margin: 0 auto,
				$max-width: 250,
				$font-size: 20,
				$padding: 15 10 12
				);
			&:active{
				box-shadow: 0 0vw 0.1vw -0.1vw hsla(0,0%,60%,.62), 0 0.05vw 0.05vw 0 rgba(0,0,0,.32), 0 0.3vw 0vw -0.2vw hsla(0,0%,60%,.4);
			}
		}
	}
}
/***  References  ***/
.b-references{
	@include vw($padding: 40 0);
	.g-site_width{
		/*@include vw($max-width: 1400);*/
	}
	header{
		@include vw(
		$margin-bottom: 90);
	}
	&_part{
		@include vw($padding: 40 0 0);
	}
	&_wrap{
		display: flex;
		@include vw($margin-bottom: 50);

		@include respond-to(small){
			flex-wrap: wrap;
			margin-bottom: 35px;
		};
		ul{
			flex-basis: 49%;
			@include vw($font-size: 22);

			&:first-child{
				margin-right: 2%;
				@include respond-to(small){
					margin-right: 0%;
				};
			}
			@include respond-to(small){
				flex-basis: 100%;
			};
			li{
				list-style-position: inside;
				line-height: 1.8;
			}
		}
	}
	&_content{
		h3{
			font-family: $ff-heavy;
			@include vw($font-size: 16,
				$margin-bottom: 5);
		}
		p{
			@include vw($font-size: 16);
		}
	}
}