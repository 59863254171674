
.b-offer_page{
	*{
		color: #58595b;
		font-family: 'Open Sans', sans-serif;
	}
	a{
		color: #4b8fac;
	}
	p{

	}
	.g-header {

		&_content{
			@include vw($padding: 180 0 0 0);
		}

	}
	.l-blue_btn {
    font-weight: bold;
    line-height: 1;
    background: #498eaa;
    color: #fff;
    border: none;
}
	.b-offer_header {
		& {
			margin: 0 auto;
			@include vw(
			$padding: 24 0 90,
				$max-width: 737);
		}
		header {
			background: #d2e3eb;
			color: $grey;
			background: #d2e3eb none repeat scroll 0 0;
			font-weight: bold;
			line-height: 1.4;
			cursor: pointer;
			@include vw(
			$padding: 5 15 7,
				$font-size: 35,
				$margin: 0 0 5);	
			&:hover, &:active, &:focus{
				color: #fff;
				text-decoration: none;
				background: #58595b;	
			}
		}
		p:nth-child(2) {
			line-height: 1.7;
			@include vw(
			$font-size: 20,
				$margin: 0 0 19);
			@include respond-to(small){
				padding: 0 15px;
			};
		}
		p:last-child {
			@include vw($margin: 0 0 0);
			@include respond-to(small){
				padding: 0 15px;
			};
		}
		p b {
			color: #488fad;
			@include vw($font-size: 21);
		}
		a {
			color: #000000;
			display: inline-block;
			text-decoration: none;
			@include vw(
			$font-size: 21,
				$margin: 2 0 0);
		}
		a:hover, a:focus {
			color: #488fad;
		}
	}
	.b-offer_header_bg{
		background: url(/images/backgrounds/main_bg.jpg);
		/*background-size: contain;*/
		background-size: cover;
		background-position: center bottom;
		background-repeat: no-repeat;
		width:100%;
		position: relative;
		@include respond-to(small) {
			background-size: cover;
		}
	}
	.l-header_bottle{
		display: inline-block;
		position: absolute;
		top: 53%;
		left: 18%;
		z-index: 9;
		@include vw($width: 100,
			$height: 90);
		@include respond-to(1200){
			left: 10%;
		};
		@include respond-to(small){
			z-index: 0;
		};
		img{
			width: 100%;
		}
	}
	/*
	==============================
	order form (offer v1) 
	==============================
	*/
	.l-offer_v1{

		/****Left Column*****/

		.c-offer_left {
			& {
				width: 66%;
				display: inline-block;
				vertical-align: bottom;
				@include respond-to(small){
					width: 100%;
				};
			}
			h1 {
				@include vw($font-size: 26);
			}
			& article > p {
				@include vw($font-size: 17);
				line-height: 1.6;
			}
		}
		.b-offer_technology {
			& {
				@include vw($margin: 43 0 0 0);
			}

			ul {
				& {
					@include vw($margin: 11 auto 0 0);
					list-style: none;
				}
				li {
					line-height: 1.5;
					@include vw(
					$font-size: 17,
						$margin: 0 0 11);
				}
				li a {
					text-decoration: none;
				}
				li::before {
					content: "\25CF";
					@include vw($font-size: 14);
				}
				li a:hover sup, ul li a:focus sup {
					color: #222;
				}
			}

			ul li a {
				text-decoration: none;
			}
			ul li a:hover sup, ul li a:focus sup {
				color: #222;
			}
		}
		.b-offer_content_important {
			& {
				background: #d3e3ec;
				float: left;
				width: 100%;
				color: #3b3737;
				@include vw(
				$padding: 20 16 20 36,
					$margin: 18 0 0);
			}
			img {
				float: left;
				@include vw($width: 128);
				height: auto;
				@include respond-to(xs){
					float: none;
					margin: 0 auto 10px;
					display: block;
				};
			}
			figcaption {
				line-height: 1.7;
				float: right;
				@include vw(
				$margin: 2 0 0 0,
					$font-size: 16,
					$width: 618);
				@include respond-to(small){
					width: calc(100% - 130px);
				};
				@include respond-to(xs){
					float: none;
					width: 100%;
				};
			}
			figcaption b {
				@include vw($font-size: 17);
			}
		}
		.b-offer_content_subs {
			& {
				background: #d9d9d9;
				float: left;
				width: 100%;
				position: relative;
				color: #3b3737;
				@include vw(
				$margin: 20 0 0,
					$padding: 20 20 80 28);
				@include respond-to(small){
					padding: 14px 14px 14px 19.6px;
				};
			}
			img {
				display: inline-block;
				vertical-align: top;
				height: auto;
				@include vw(
				$width: 214,
					$margin: 6 0 0);
				@include respond-to(small){
					width: 45%;
					max-width: 90px;
					display: block;
					margin: 0 auto 15px;
				};
			}
			&_content {
				display: inline-block;
				vertical-align: top;
				@include vw(
				$width: 500,
					$margin: 0 0 0 29);
				@include respond-to(small){
					width: auto;
				};
			}
			h3 {
				@include vw($margin: 0 0 11 0);
			}

			ul li {
				@include vw(
				$font-size: 17,
					$margin: 0 0 4);
				line-height: 1.6;
			}
			footer {
				position: absolute;
				font-weight: bold;
				font-family: $ff-heavy;
				bottom: 20px;
				right: -20px;
				background: url('/images/blue_big_arrow.png') no-repeat top right;
				background-size: cover;
				text-align: right;
				line-height: 1;
				@include vw(
				$right: -58,
					$bottom: 6,
					$width: 775,
					$height: 49,
					$font-size: 23,
					$padding: 7 68 0 0);
				@include respond-to(small){
					display: none;
				};
			}
		}

		/***** Right Column ********/
		.c-offer_right {
			& {
				float: none;
				display: inline-block;
				vertical-align: bottom;
				width: 30%;
				margin-left: 3%;
				@include respond-to(small){
					float: none;
					width: 100%;
					margin-left: 0;
					max-width: 320px;
					margin: auto;
					display: block;
				};
			}
		}
		.l-member {
			.j-member_subs {
				display: none;
			}
			&_row {
				& {
					float: left;
					width: 100%;

					@include vw($margin: 0 0 24);
					@include respond-to(small){
						margin: 0px auto 16.8px;
						max-width: 300px;
						display: block;
						float: none;
						width: 100%;

					};
				}
				header {
					background: #d2e3eb none repeat scroll 0 0;
					color: #585757;
					font-weight: bold;
					line-height: 1;
					text-align: center;
					@include vw(
					$margin: 0 0 8,
						$padding: 5,
						$font-size: 29);
				}
				a {
					background: #d9d9d9;
					color: #585757;
					display: block;
					float: left;
					text-decoration: none;
					width: 100%;
					@include vw($padding: 12 23);
				}

				span {
					display: block;
				}
				&_checkbox {
					& {
						border-style: solid;
						border-color: #d2e3eb;
						text-align: center;
						background: #fff none repeat scroll 0 0;
						display: block;
						float: left;
						padding: 3px;
						@include vw(
						$border-width: 3,
							$width: 50,
							$height: 39,
							$padding: 3);
					}
					img {
						@include vw($width: 33);
						display: none;
					}
				}
				&_link {
					& {
						float: right;
						font-size: 20px;
						line-height: 1;
						@include vw($width: 255);
					}

					&_price {
						@include vw($font-size: 22);
						font-weight: bold;
						span{
							display: inline;
						}
					}
					&_regular {
						@include vw(
						$font-size: 14,
							$margin: 10 0 0);
					}
					&_saving {
						@include vw(
						$font-size: 17,
							$margin: 10 0 0);
						color: #4f91ac;
						font-weight: bold;
					}
				}

				a:hover .l-member_row_link_price, a:focus .l-member_row_link_price  {
					color: #488fad;
				}
				& > a:hover .l-member_row_checkbox, & >a:focus .l-member_row_checkbox {
					border-color: #488fad;
				}
			}
			&_join {
				@include respond-to(small){
					margin: 0px auto 16.8px;
					max-width: 300px;
					display: block;
					float: none;
					width: 100%;

				};
				a {
					background: #a9cad8;
					float: left;
					width: 100%;
					display: block;
					@include vw($padding: 12 23);
				}
				b {
					color: #4f91ac;
					display: block;
					float: left;
					line-height: 1.4;
					text-align: center;
					width: 100%;
					@include vw(
					$font-size: 26,
						$margin: 0 0 15);
				}
				.l-member_row_link {
					color: #3b3737;
					font-weight: bold;
					text-transform: uppercase;
					@include vw($font-size: 20);
				}
				a:hover, a:focus {
					background: #488fad;
					cursor: pointer;
					color: #fff;
				}
				a:hover b, a:focus b {
					color: #fff;
				}
				& > a:hover .l-member_row_checkbox, & > a:focus .l-member_row_checkbox {
					border-color: #fff;
				}
			}

		}
		.j-package_deal_save {
			display: none;
		}
		.l-member_package_row a:hover {
			color: #498da9;
		}
		.l-member_package_row a.active img, .b-member_join a.active .b-member_package_checkbox img {
			display: block;
		}

	}
	/*
	=======================================
			Offer Content
	=======================================
	*/
	.b-offer_content {
		@include vw($margin: 33 0 0);
		@include respond-to(small){
			margin: 0;
		};
	}

	/*
	======================================
	Column Info Block (faq)
	======================================
	*/
	/*
	=========================================
	 sub info (faq section after order form)
	=========================================
	*/
	.b-subs_info {
		& {
			@include vw($margin: 32 0 20);
		}
		h5 {
			color: #3b3838;
			font-weight: bold;
			@include vw(
			$margin: 0 0 5,
				$font-size: 17);
			@include respond-to(small){
				font-size: 14px;
			};
		}
		p {
			@include vw($font-size: 14);
			color: #7e7e7e;
			line-height: 1.3;
			@include respond-to(small){
				font-size: 14px;
			};
		}
		p b {
			color: #3b3838;
			font-weight: normal;
			@include vw($font-size: 17);
			@include respond-to(small){
				font-size: 12px;
			};
		}
		.c-offer_left {
			float: left;
			width: 70%;
			@include respond-to(small){
				padding: 0;
				margin: 0;
				float: none;
				width: 100%;
			};
		}
		.c-offer_right {
			float: right;
			width: 27%;
			margin-left: 3%;
			@include respond-to(small){
				padding: 0;
				margin: 0;
				float: none;
				width: 100%;
			};
		}
		&_icons{
			figure{
				width: 25%;
				float: left;
				margin: 0 2%;
				text-align: center;
				@include respond-to(small){
					width: auto;
					float: none;
					display: inline-block;
				};
				img{
					max-width: 100%;
					@include vw($width: 50);
				}
				span{
					font-weight: bold;
					line-height: 1;
					display: block;
					@include vw(
					$font-size: 14,
						$margin-top: 10);
				}			
			}

		}
	}
	.b-subs_info {
		.c-offer_left {
			float: left;
			width: 66%;
			@include respond-to(small){
				padding: 0;
				margin: 0;
				float: none;
				width: 100%;
			};
		}
		.c-offer_right {
			width: 30%;
			margin-left: 3%;
			@include respond-to(small){
				padding: 0;
				margin: 0;
				float: none;
				width: 100%;
				max-width: 100%;
			}
		}
	}
	/*
	=================================
		guarantee
	=================================
	*/
	.l-guarantee {
		& {
			width: 100%;
			@include vw($margin: 5 0 0);
		}
		.g-site_width {
			display: flex;
			justify-content: space-between;
			padding: 0;
			flex-wrap: wrap;
			@include respond-to(small){
				padding: 0 10px;
			};
		}
		figure {
			background: #d0e2ea;
			vertical-align: middle;
			text-align: center;
			flex-grow: 1;
			@include vw($margin: 0 10 15,
				$flex-basis: 280,
				$padding: 21 5 33);
			@include respond-to(small){
				margin: 0 5px 10px;
			};
		}
		img {
			@include vw(
			$width: 100,
				$margin: 0 0 25);
		}
		span {
			color: #585757;
			display: block;
			font-weight: bold;
			line-height: 1;
			@include vw(
			$font-size: 22,
				$min-height: 61);
		}
		a {
			@include vw($min-width: 201);
		}
		a:hover, a:focus {
			text-decoration: none;
			background: #585757;
			color: #fff;
		}
	}
	/*
	=================================
	supplements(offer&product page)	(chromaDex)
	=================================
	*/
	.l-supplements {
		& {
			@include vw($margin: 55 0 0);
		}
		.g-site_width {
			display: flex;
			justify-content: space-between;
			align-items: center;
			@include respond-to(600){
				flex-wrap: wrap;
			};
		}
		.c-supplements_left {
			& {
				background: url('/images/chomadex_bg.jpg') no-repeat center left;
				background-size: contain;
				width: 50%;
				@include vw($min-height: 450);
				@include respond-to(600){
					width: 100%;
					text-align: center;
				};
			}
			img {
				@include vw($width: 366);
			}
			p {
				font-weight: bold;
				text-align: center;
				@include vw(
				$font-size: 20,
					$margin: 56 0 39);

			}
			.l-blue_block {
				& {
					line-height: 1.4;
					margin: 0 auto;
					max-width: 61%;
					@include vw($padding: 9 5 5);
					display: block;
					text-align: center;
					@include respond-to(small){
						max-width: 90%;
					};
					@include respond-to(600){
						max-width: 315px;
						width: 90%;
					};
				}
				b {
					color: #3b3838;
					font-weight: bold;
				}
				&:hover, &:focus {
					color: #3b3838;
					text-decoration: none;
					background: #bfbfbf;
				}
			}
		}
		.c-supplements_right {
			& {
				width: 45%;
				@include vw($margin: 18 0 0);
				@include respond-to(small){
					width: 60%;
				};
				@include respond-to(600){
					width: 100%;
				};
			}
		}
	}
}
	.b-review{
		&_foto{
			.l-reviews_container{
				@include vw($padding: 10 15);
				background: #f9f9f9;
				@include vw($margin-bottom: 20);
			}
			&_wrap{
				width: 60%;
				@include vw($margin: 0 auto 30);
				@include respond-to(xs){
					width: 94%;
				};

				p{
					text-align: center;
					@include vw($font-size: 20);
				}

			}
			img{
				text-align: center;
				max-width: 60%;
				@include vw($width: 500);
				margin: auto;
				display: block;
				@include respond-to(xs){
					max-width: 100%;
				};

			}
		}
	}
