
.l-header_bg .g-header_content{
	@include vw($padding: 330 0 135 0 );
	text-align: center;
}
.b-terms{
	@include vw($font-size: 22,
		$padding: 0 0 150 0);
	@include respond-to(small){
		padding: 0 0 50 0
	};
	header{
		text-align: center;
		@include vw($padding: 330 0 135 0 );
		@include vw($margin-bottom: 35);
		h3{
			@include vw(
			$font-size: 22,
				$margin: 20 0 30 );
			line-height: 1.4;
		}
		h2{
			width: 73%;
			margin: auto;
			line-height: 1.3;
			@include vw($font-size: 26);
			@include respond-to(small){
				width: 100%;
			};
		}
	}
	section{
		h3{
			font-weight: bold;
			color: $brand;
			text-decoration: underline;
			line-height: 1.2;
			@include vw(
			$font-size: 22,
				$margin: 0 0 7);
		}
		h2{
			font-weight: 600;
			@include vw(
			$font-size: 30,
				$margin: 62 0 40 0,
				$letter-spacing: 2.2);
			&.r-padding{
				margin-bottom: 0;
			}
		}
	}
	p{
		@include vw(
		$font-size: 22,
			$margin-bottom: 30px);
		&.r-padding{
			margin-bottom: 0px;
		}
	}
	.b-row_dotted {
		border-bottom: 1px dotted;
		position: relative;
		@include vw($margin-top: 30);
		@include respond-to(xs){
			display: flex;
			justify-content: space-between;
			border-bottom: none;
		};
		.b-first-column {
			@include vw(
			$top: -23,
				$padding-right: 10);
			display: inline-block;
			position: absolute;
			left: 0;
			background: #fff;
			@include respond-to(xs){
				padding-right: 10px;
				position: static;
			};
		}
		.b-second_column {
			@include vw(
			$top: -23,
				$padding-left: 19);
			display: inline-block;
			position: absolute;
			right: 0;
			background: #fff;
			@include respond-to(xs){
				padding-left: 10px;
				position: static;
				text-align: right;
			};
		}
	}
	.b-title{
		display: flex;
		justify-content: space-between;
		@include vw($font-size: 22);
		span{
			text-decoration: underline;
		}
	}
	h4{
		@include vw(
		$font-size: 22,
			$margin: 0 0 5);
		text-decoration: underline;
		&.r-padding{
			@include vw($margin: 43 0 5);
		}
	}

	.b-autoship_contact{
		@include vw($margin-bottom: 0);
		a{
			display: block;
		}
	}
	.r-lg_margin{
		h3{
			@include vw($margin-bottom: 60);
		}
	}
	.r-address{
		line-height: 1.5;
		@include vw($margin-bottom: 35);
	}
	&_contact{
		width: 70%;
		@include respond-to(small){
			width: 100%;
		};
		.b-row_dotted {
			margin: 0;
		}
		p{
			&:last-child{
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				@include vw($margin-top: 10);
			}
		}
	}
}