.b-index{
	.b-hero{
		background: url(/images/hero-bg.jpg) no-repeat top left;
		background-size: cover;
		overflow: hidden;

		.g-site_width{
			display: flex;
			justify-content: space-between;
			position: relative;
			@include vw($margin-top: 60,
				$margin-bottom: 10);
			@include respond-to(small){
				margin-top: 0px;
			};
		}
		&_left{
			flex-basis: 64%;
			@include vw($margin-top: 30);
			@include respond-to(small){
				flex-basis: 100%;
				margin-bottom: 50px;
			};
		}
		&_right{
			flex-basis: 31%;
			text-align: right;
			@include vw($padding: 0 60);
			@include respond-to(small){
				display: none;
			};
			img{
				width: 100%;
				@include vw($max-width: 300);
			}
		}
	}

	/***  HELP   ***/
	.b-help{
		.j-help_prestep{
			opacity: 0;
			@include respond-to(small){
				opacity: 1;
			};
		}

		.g-site_width{
			/*@include vw($max-width: 1360);*/
		}
		&_slider{
			transition: all 0.5s ease;
			position: relative;
			@include vw($height: 690);
			@include respond-to(small){
				height: 100%;
			};
		}
		&_slide{
			position: absolute;
			float: left;
			display: flex;
			justify-content: space-between;
			width: 100%;
			height: 100%;
			transition: all 0.5s ease-out;
			transform: translateX(0%);
			opacity: 1;
			/*opacity: 0;*/
			&.first{
				@include respond-to(small){
					position: relative;
					justify-content: center;
				};
				.b-help_blue{
					@include respond-to(small){

						display: none;
					};
				}

			}
			&.second{
				flex-wrap: wrap;
				transform: translateX(100%);
				/*transform: translateX(0%);*/
				opacity: 0;
				/*opacity: 1;*/

				h2{
					flex-basis: 100%;
				}
				.b-help_blue{
					@include vw($padding-top: 0);
					flex-basis: 55%;
					@include respond-to(small){
						flex-basis: 100%;
					};
					&_block{

						background: rgb(210, 237, 248);
					}
				}
			}
		}
		&_chart{
			max-width: 100%;
			@include vw($width: 570,
				$margin: 0 auto);
			@include respond-to(small){
				display: none;
			};
		}
		&_form{
			flex-basis: 55%;
			color: #000;
			font-family: $ff-sec;
			@include vw($font-size: 30);
			@include respond-to(small){
				flex-basis: 80%;
			};
			@include respond-to(600){
				flex-basis: 100%;
			};
			*{
				font-weight: 600;
			}
			&_item{
				display: inline-block;
				position: relative;
				@include vw($margin-bottom: 40);
				@include respond-to(xs){
					margin-bottom: 15px;
				};
				&>span{
					display: inline-block;
					transition: all 0.5s ease-in;
					@include vw($margin-right: 9);
					@include respond-to(xs){
						font-size: 16px;
					};
				}
				&.full{
					width: 100%;
				}
				p{
					display: inline-block;
				}
				.b-help_form_arrow{
					background: url(/images/arrow_blue.png) no-repeat center left;
					background-size: cover;
					position: absolute;
					max-width: 100%;
					color: $red;
					text-transform: uppercase;

					@include vw($margin: 0,
						$width: 350,
						$height: 50,
						$line-height: 42,
						$right: -50,
						$top: -7,
						$padding: 3 40);
					@include respond-to(600){
						display: none;
					};
				}

				/*  Chosen Form */
				.chosen-container{
					@include vw($font-size: 26);
					@include vw($width: 272 !important);
					.chosen-single{
						border: 0;
						border-radius: 0;
						background: $l-grey;
						background-clip: padding-box;
						color: #fff;
						text-decoration: none;
						white-space: nowrap;

						@include vw($padding: 0 20,
							$height: 55,
							$line-height: 55,
							$box-shadow: 0 0 3 #fff inset);
						span {
							@include vw($margin-right: 30);
						}
						div {
							@include vw($width: 20);
							b {
								@include vw($background-size: 140 85 !important,
									$background-position: -7 7);

							}
						}
					}
					.chosen-results {
						margin: 0;
						padding: 0;
						@include vw($max-height: 250);
						li{
							@include vw($padding: 12 7,
								$line-height: 20);
							list-style: none;
						}
						li.highlighted {
							background-color: $brand;
							background-image: linear-gradient($brand 20%,$brand 90%);
							color: #fff;
						}
					}
					.chosen-drop {
						background-clip: padding-box;
						@include vw($border-radius: 0 0 4 4,
							$margin-top: -1,
							$border: 1 solid #aaa,
							$box-shadow: 0 4 5 rgba(0,0,0,.15));
					}
				}
				/* End Chosen Form */


			}
			&_input{
				background: $l-grey;
				border: none;
				color: #fff;
				width: 34%;

				@include vw($padding: 0 7,
					$height: 55,
					$line-height: 50,
					$min-width: 260,
					$box-shadow: 0 0 3 #fff inset);
				@include respond-to(small){

				};
				@include placeholder{
					color: #fff;

				};
				@include respond-to(xs){
					height: 30px;
					min-width: 105px;
					font-size: 16px;
					margin-bottom: 0px;
				};
				&.r-first{
					width: 75%;
					@include respond-to(xs){
						width: 65%;
					};
				}
			}

			&_list{
				/*width: 85%;*/
				@include respond-to(small){
					/*width: 90%;*/
					margin: auto;
				};
				@include respond-to(xs){
					width: 100%;
				};
				&>span{
					display: inline-block;
					@include vw($font-size: 30,
						$margin: 10 0 30);
					@include respond-to(xs){
						font-size: 14px;
						margin-bottom: 10px;
					};
				}
				&_title{
					position: relative;
					color: $brand;
					background: $l-blue;
					background: linear-gradient(to right, rgba(210,227,234,0.3) 0%,$l-blue 33%,$l-blue 100%);
					@include vw($padding: 7 10,
						$margin: 20 0 );
					&:before{
						content: '';
						width: 100%;
						height: 100%;
						left: -100%;
						top: 0;
						position: absolute;
						background: rgba(210, 227, 234, 0.3);
					}
					@include respond-to(xs){
						text-align: center;
					};
				}
				&_items{
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;

				}
				&_item{
					flex-basis: 49%;
					label{
						width: 100%;
						background: #ccc;
						text-align: center;
						position: relative;
						cursor: pointer;
						color: #000;
						transition: all 0.5s ease-out;
						@include vw($padding: 15 7,
							$font-size: 26,
							$margin-bottom: 12,
							$box-shadow: 0 0 0 2 transparent);
						@include respond-to(xs){
							font-size: 16px;
						};
					}
					&.disabled{
						label{
							background: #ccc;
							color: #070707;
							cursor: default;
							&:hover{
								cursor: not-allowed
									;	
							}
						}
					}
					input{
						display: none;
						&:checked + label{
							@include vw(
							$box-shadow: 0 0 0 2 $brand);
							background: $brand;
							@include respond-to(small){
                                box-shadow: 0 0 0 2px $brand;
							};

						}
					}
				}
			}
			&_btn{
				/*				width: 85%;*/
				text-align: right;
				@include vw($margin: 25 0 0,
					$font-size: 30,
					$padding-right: 45);
				@include respond-to(small){
					width: 100%;
				};
				@include respond-to(xs){
					margin: 10px 0px 0px;
				};
				button{
					font-family: $ff;
					color: #fff;
					display: inline-block;
					background: $brand;
					position: relative;
					opacity: 0.5;
					cursor: not-allowed;
					@include vw($font-size: 55,
						$max-width: 200,
						$border: 0,
						$padding: 9 5 2);

					@include respond-to(xs){
						font-size: 24px;

					};
					&:hover, &:active, &:focus{
						@include vw(
						$border: 0);
						background: $brand;
					}
					&.active{
						opacity: 1;
						cursor: pointer;
						&:hover, &:active, &:focus{
							@include vw(
							$border: 0);
							background:$grey;
							&:before{
								content: '';
								@include vw($border: 39.2 solid transparent,
									$border-left: 39.2 solid $grey,
									$right: -78);
								@include respond-to(xs){
									border: 19px solid transparent;
									border-left: 19px solid $grey;
									right: -38px;
								};
							}
						}
					}

					&:before{
						content: '';
						position: absolute;
						top: 0;
						@include vw($border: 39.2 solid transparent,
							$border-left: 39.2 solid $brand,
							$right: -78);
						@include respond-to(xs){
							border: 19px solid transparent;
							border-left: 19px solid $brand;
							right: -38px;
						};
					}
				}
			}

		}
		&_blue{
			flex-basis: 38%;
			@include vw($padding: 0 0 0);
			@include respond-to(small){
				flex-basis: 100%;
				margin-right: 0;

			};
			&_block{
				background: rgba(41,171,226, 0.8);
				position: relative;
				@include vw($padding: 0,
					$min-height: 672);
				&_content{
					&.b-first_step{
						color: #fff;
						@include vw($padding: 45 40);
						p{
							@include vw($margin-bottom: 0);
							&:first-child{
								@include vw($font-size: 46);
							}
							&:nth-child(2){
								@include vw($font-size: 38,
									$margin-bottom: 7);
								opacity: 0.8;
							}
							&:nth-child(3){
								@include vw($font-size: 88);
								line-height: 1;
							}
							&:nth-child(4){
								@include vw($font-size: 50,
									$margin-bottom: 35);
								line-height: 1;
							}
							&:nth-child(5){
								@include vw($font-size: 32);
							}
							&:nth-child(6){
								display: flex;
								&>span{
									&:first-child{
										line-height: 1;
										display: inline-block;
										@include vw($font-size: 118,
											$margin-right: 25);
									}
									&:last-child{
										display: block;
										line-height: 1;
										@include vw($font-size: 43,
											$margin-top: 0);
										span:last-child{
											display: block;
											line-height: 1;
											@include vw($font-size: 66,
												$margin: 0);

										}
									}
								}
							}
							&:nth-child(7){
								@include vw($font-size: 43,
									$margin-bottom: 7);
								opacity: 0.8;
							}
							&:nth-child(8){
								display: flex;
								&>span{
									&:first-child{
										line-height: 1;
										display: inline-block;
										@include vw($font-size: 86,
											$letter-spacing: -3,
											$margin-right: 5);
									}
									&:last-child{
										display: block;
										line-height: 1;
										@include vw($font-size: 43,
											$margin-top: -7);
										span:last-child{
											display: block;
											line-height: 1;
											@include vw($font-size: 66,
												$margin: 0);

										}
									}
								}
							}
						}
					}

					&.b-second_step{
						display: none;
						position: absolute;
						top:0;
						width: 100%;
						height: 100%;
						@include vw($padding: 45 45);
						header{
							@include vw($font-size: 38,
								$margin-bottom: 15);
							font-weight: bold;
							color: $text;
							span{
								font-weight: inherit;
							}
						}
						.b-text_typing{
							@include vw($font-size: 28);
							color: $text;
							font-weight: 600;
							span{
								font-weight: inherit;
								display: inline-block;
								line-height: 1;
								@include vw($padding: 90 0 90);
							}

						}
						.typed-cursor{
							display: none ;
						}
						p{
							/*display: none;*/
						}

					}
				}
				&_arrow{
					color: $text;
					@include vw(
					$font-size: 28);
					padding-left: 20%;
					width: 100%;
					font-weight: bold;
					opacity: 0;
					position: relative;
					transition: all 0.5s ease-out;
					margin-left: 22%;
					@include vw($padding-left: 40,
						$margin-top: 90);
					&:before{
						content: '';
						position: absolute;
						top: 0;

						@include vw($border: 39.5 solid transparent,
							$border-right: 39.5 solid $text,
							$left: -50,
							$top: -5);
					}
				}
			}
			&_footer{
				@include vw($margin-top: 30);
				position: relative;
				img{
					position: absolute;

					@include vw($width: 120,
						$left: -120,
						$top: 0);
				}
			}
			&_reorder{
				color: $text;
				font-weight: 600;
				font-family: $ff-sec;
				text-align: center;
				@include vw($font-size: 22);
				a{
					color: $text;
					text-decoration: underline;
					font-weight: inherit;
					&:hover, &:active, &:focus{
						color: $text;
						text-decoration: none;	
					}
				}
			}
		}
		&_patient{
			@include vw($padding: 40 30);
			@include respond-to(small){
				padding-left: 10px;
				padding-right: 10px;
			};
			&_content{
				position: relative;
			}
			&_header{
				color: $text;
				font-weight: 600;
				line-height: 1;
				text-transform: uppercase;
				font-family: $ff-sec;
				@include vw($font-size: 42,
					$margin-bottom: 20);

			}
			&_profile{
				display: flex;
				flex-wrap: wrap;

			}
			&_item{
				color: #000;
				font-family: $ff-medium;
				text-transform: uppercase;
				@include vw($font-size: 28,
					$margin-bottom: 25);
				flex-basis: 49%;
				margin-right: 1%;

				span{
					color: $text;
					text-transform: none;
					display: inline-block;
					@include vw($margin-left: 15);
				}
				&.name{
					flex-basis: 100%;
					margin-right: 0%;
				}
			}
			&_tabs{
				display: flex;
				flex-wrap: wrap;

				justify-content: space-around;
				&_wrap{
					@include vw($padding-top: 10);
				}
				&_header{
					color: #000;
					font-weight: bold;
					@include vw($font-size: 26,
						$margin-bottom: 10);
				}
				li{
					display: none;
					cursor: pointer;
					background: #ccc;
					text-align: center;
					position: relative;
					cursor: pointer;
					color: #000;
					font-weight: bold;
					transition: all 0.5s ease-out;
					flex-basis: 48%;
					flex-grow: 0;
					@include vw($padding: 15 20,
						$font-size: 26,
						$margin-bottom: 12,
						$box-shadow: 0 0 0 2 transparent);
					@include respond-to(xs){
						font-size: 15px;
						padding: 10px;
						flex-basis: 49%;
					};
					&:hover{
						background: $brand;
						color: #fff;
					}
					&.checked{
						display: inline-block;
						/*background: $brand;*/
					}
				}
				&_footer{
					position: absolute;
					font-weight: bold;
					@include vw($bottom: 40);
					span{
						position: absolute;
						right: 0;
						@include vw($border: 20.5 solid transparent,
							$border-bottom: 20.5 solid $text,
							$right: -60,
							$top: -20);
					}
				}
			}
			.l-btn{
				margin-left: 0;
				position: absolute;

				@include vw($padding: 3 7,
					$width: 150,
					$bottom: 15);
			}
		}
		&_info{
			&_wrap{
				flex-basis: 41%;
				position: relative;
				overflow: hidden;
				@include respond-to(small){
					flex-basis: 100%;
					margin-right: 0%;
					margin-top: 30px;
				};

			}
			&_btns{
				position: absolute;
				right: 0;
				width: 100%;
				@include vw($bottom: 10);
				display: flex;
				justify-content: space-between;
				.l-btn{
					position: relative;

					font-family: $ff;
					color: #fff;
					display: inline-block;
					background: $brand;
					transition: all .3s ease-in;
					@include vw($font-size: 30,
						$max-width: 200,
						$border: 0,
						$padding: 15 5 8);
					&:hover, &:active, &:focus{
						@include vw(
						$border: 0);
						background:$grey;

					}
					&.right{
						&:before{
							content: '';
							position: absolute;
							top: 0;
							transition: all .3s ease-in;
							@include vw($border: 30 solid transparent,
								$border-left: 30 solid $brand,
								$right: -60);
						}

						&:hover{
							&:before{
								content: '';
								@include vw($border: 30 solid transparent,
									$border-left: 30 solid $grey,
									$right: -60);
							}	
						}

					}
					&.left{
						&:before{
							content: '';
							position: absolute;
							top: 0;
							transition: all .3s ease-in;
							@include vw($border: 30 solid transparent,
								$border-right: 30 solid $brand,
								$left: -60);
						}
						&:hover{
							&:before{
								content: '';
								@include vw($border: 30 solid transparent,
									$border-right: 30 solid $grey,
									$left: -60);
							}
						}

					}


				}
			}

			&_item{
				display: none;
				&_header{
					color: $brand;
					font-family: $ff-medium;
					@include vw($font-size: 32);
				}
				&.checked{
					display: block;	
				}
			}
			&_part{
				display: none;
				@include respond-to(small){
					display: block !important;
				};
				&.active{
					display: block;
				}
			}
		}
		.slick-slider{
			@include respond-to(small){
				padding-bottom: 50px;
			};
		}
		.l-disclaimer{
			text-align: left;
			color: $grey;
			@include vw($font-size: 22);
			@include respond-to(xs){
				margin-top: 15px !important;
			};
		}
	}

	/***  Grey  ***/
	.b-grey{
		background: #f2f2f2;
		text-align: center;
		position: relative;
		z-index: 9999;
		&_blocks{
			display: flex;
			justify-content: space-between;
			@include vw($margin-bottom: 85);
			@include respond-to(small){
				flex-wrap: wrap;
				margin-bottom: 20px;
			};
		}
		&_block{
			flex-basis: 46%;
			background: #cae4ef;
			transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
			box-shadow: 0 0.2vw 0.2vw 0 rgba(153, 153, 153, 0.14),
				0 0.3vw 0.1vw -0.2vw rgba(153, 153, 153, 0.2),
				0 0.1vw 0.5vw 0 rgba(153, 153, 153, 0.12);
			@include vw($padding: 65 20 60);
			@include respond-to(small){
				flex-basis: 98%;
				margin-bottom: 15px;
			};
			&:hover, &:active, &:focus{
				box-shadow: 0 1.4vw 2.6vw -1.22vw rgba(153, 153, 153, 0.42), 
					0 0.4vw 2.3vw 0vw rgba(0, 0, 0, 0.12), 
					0 0.8vw 1.0vw -0.5vw rgba(153, 153, 153, 0.2);	
			}
			h3{
				@include vw($font-size: 38,
					$margin-bottom: 35);
				color: $text;
			}
			p{
				color: $text;
				@include vw($font-size: 28,
					$margin-bottom: 5);
			}
		}
		&_contact{
			display: flex;
			justify-content: space-around;
			a{
				font-family: $ff-sec;
				color: $text;
				text-align: right;
				@include vw($font-size: 38);
				@include respond-to(xs){
					font-size: 12px;
				};
				&:nth-child(2){
					text-align: right;
					@include respond-to(600){
						text-align: center;
					};
				}
				&:hover, &:active, &:focus{
					color: $text;
					text-decoration: underline;
				}
			}
		}
	}

	/***  Inform  ***/
	.b-inform{
		.g-site_width{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			@include respond-to(small){

			};
		}
		&_grey{
			color: rgb(117,113,113);
			text-align: center;
			font-family: $ff-sec;
			font-weight: 600;
			@include vw($font-size: 16);
		}
		&_left{
			flex-basis: 55%;
			p{
				span{
					font-family: $ff-heavy;
				}
			}
			@include respond-to(small){
				flex-basis: 100%;
			};
		}
		&_right{
			flex-basis: 50%;
			margin-right: -10%;
			@include vw($padding: 40 0 0);
			@include respond-to(small){
				flex-basis: 100%;
				margin-right: 0%;
			};
		}
		&_options{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			@include respond-to(small){
				justify-content: space-around;
			};
			&_header{
				flex-basis: 100%;
				display: flex;
				justify-content: space-between;
				align-items: flex-end;
				@include vw($padding-bottom: 10);
				span{
					@include vw($font-size: 22);
					color: $text;
					font-family: $ff-sec;
					font-weight: 600;
					line-height: 1;
				}
				img{
					max-width: 100%;
					@include vw($width: 150);
				}
			}
			&_item{
				flex-basis: 46%;
				flex-grow: 0;
				text-align: center;
				background: linear-gradient(0deg, #fff 0%,  #e8e8e8 80%);
				@include vw($padding: 30 0,
					$margin-bottom: 30);
				@include respond-to(small){
					margin-bottom: 30px;
					flex-basis: 48%;
					padding: 10px 0px;
				};
			}
			&_dose{
				font-family: $ff-heavy;
				color: $brand;
				@include vw($font-size: 18.6,
					$margin-bottom: 10);
			}
			&_title{
				@include vw($font-size: 24,
					$margin-bottom: 20);
				color: #000;
				font-family: $ff-sec;
				font-weight: 600;
				&.r-margin{
					@include vw($margin-bottom: 0);
				}
			}
			&_img{
				img{
					max-width: 100%;
					@include vw($width: 130);
				}
			}
			&_new{
				background: $yellow;
				color: #000;
				text-transform: uppercase;
				font-family: $ff-sec;
				font-family: 600;
				@include vw($font-size: 16,
					$padding: 4 7 4,
					$margin-bottom: 0);
			}
			&_img{
				display: flex;
				align-items: center;
				justify-content: center;
				span{
					font-weight: 600;
					font-family: $ff-sec;
					@include vw($font-size: 24,
						$margin: 0 0 0 15);
				}
			}
			&_reorder{
				color: $text;
				font-weight: 600;
				font-family: $ff-sec;
				text-align: center;
				@include vw($font-size: 22);
				a{
					color: $text;
					text-decoration: underline;
					font-weight: inherit;
					&:hover, &:active, &:focus{
						color: $text;
						text-decoration: none;	
					}
				}
			}

		}
		.l-btn{
			@include vw($margin-top: 20);
		}
	}
	/***  DR. LEO  ***/
	.b-dr{
		hgroup{
			.g-site_width{
				display: block;
			}
		}
		.g-site_width{
			display: flex;
			justify-content: space-between;
			@include respond-to(small){
				flex-wrap: wrap;
			};
		}
		&_grey{
			color: rgb(117,113,113);
			font-family: $ff-sec;
			font-weight: 600;
			@include vw($font-size: 22);
			display: none;
		}
		&_left{
			width: 75%;
			flex-grow: 0;
			position: relative;

			@include respond-to(small){
				flex-basis: 100%;
				max-width: 500px;
				margin: 0 auto;
			};
			header{
				margin-right: 25%;
				@include respond-to(small){
					margin-right: 0%;
				};
			}

		}
		&_slider{
			opacity: 0;
			display: none;
			.r-dr_slider_group{
				label{
					@include respond-to(small){
						margin-right: 25px;
					};
				}
			}
			&_wrap{
				display: none;
				text-align: center;
				@include vw($padding: 40 0 0);
			}
			&_title{
				color: $text;
				font-family: $ff-sec;
				font-weight: 600;
				@include vw($font-size: 37,
					$margin-bottom: 20);
			}
			&_descr{
				span{
					color: $brand;
				}
			}
			input[type=text]{
				background: $l-grey;
				border: none;
				color: #fff;
				max-width: 100%;
				display: inline-block;
				transition: all 0.5s ease-out;
				@include vw($padding: 3 7,
					$height: 55,
					$width: 450,
					$box-shadow: 0 0 0 2 $l-grey);
			}
			&_dots{
				display: none;
				span{
					display: inline-block;
					font-weight: bold;

					&:first-child{
						font-family: $ff-heavy;
						@include vw($margin-right: 20);
					}
				}
				ul{
					li{
						display: inline-block;
						border-radius: 50%;
						background: $l-grey;
						@include vw($margin-right: 7
							);
						&:first-child{
							background: $brand;
						}
						button{
							border-radius: 50%;
							padding: 0;
							background: inherit;
							color: #fff;
							@include vw($border: 0,
								$width: 30,
								$height: 30,
								$line-height: 30,
								$font-size: 18);
							display: flex;
							align-items: center;
							justify-content: center;
							text-align: center;

						}
						&.slick-active{
							background: #4b8fac;
							button{

								color: #fff;
								/*line-height: 1;*/
							}
						}
					}
				}
			}
			&_group{
				display: flex;
				flex-wrap: wrap;
				justify-content: space-around;
				width: 95%;
				margin: 0 auto;
				&_col{
					span{
						transition: all 0.5s ease-out;
						display: block;
						/*						font-weight: bold;
												color: $text;*/
					}
				}
				&.r-width{
					@include respond-to(xs){
						max-width: 240px;
						justify-content: flex-start;
					};
				}
			}

		}
		.l-btn{
			@include vw($margin: 30 auto,
				$max-width: 300);
		}
		&_bottle{
			flex-basis: 30%;
			@include vw($padding: 50 0 0);
			@include respond-to(small){
				display: none;
			};
			img{
				width: 100%;
				@include respond-to(xs){
					width: 129px;
				};
			}
		}
		&_leo{
			display: block;
			position: absolute;
			text-align: center;
			@include vw($width: 300,
				$height: 340,
				$left: -150,
				$bottom: -30);
			@include respond-to(small){
				display: none !important;
			};
		}

		/***  Dr. Leo assessment  ***/
		&_assessment{
			display: none;
			/*display: block;*/
			.j-part{
				display: none;
			}
			.j-part_1{
				display: block ;
			}
			header{
				flex-basis: 100%;
				@include vw($margin-bottom: 20);
			}
			.b-part{
				display: none;
				&.active{
					display: block;
				}
			}
			&_data{
				display: none;
			}
			.g-site_width{
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
			}
			&_leo{
				flex-basis: 20%;
				margin-right: 2%;
				img{
					max-width: 100%;
				}
				@include respond-to(xs){
					flex-basis: 100%;
					margin: 0 auto 30px;
					max-width: 240px;
				};
			}
			&_content{
				position: relative;
				&_wrap{
					flex-basis: 78%;
					@include respond-to(xs){
						flex-basis: 100%;
					};
				}

			}
			&_arrow{
				position: absolute;
				transform: rotate(8deg);
				@include vw($width: 120,
					$right: -140,
					$top: 60);
			}

			&_btns{
				display: flex;
				text-align: center;
				justify-content: space-around;
				@include vw($margin-bottom: 30);
				a.l-btn{
					@include vw($max-width: 350,
						$margin: 10);
					&.prev{
						display: none;
					}
				}
			}
			&_form{
				text-align: center;
				p{
					@include vw($margin: 0 auto 10,
						$font-size: 26);
					&:nth-child(3){
						color: $brand;
						font-weight: bold;
					}
				}
				form{
					input[type=text]{
						background: $l-grey;
						border: none;
						color: #fff;
						max-width: 100%;
						display: inline-block;
						transition: all 0.5s ease-out;
						@include placeholder{
							color: #fff;
							text-align: center;
						};
						@include vw($padding: 3 7,
							$height: 50,
							$width: 450,
							$box-shadow: 0 0 0 2 $l-grey);
					}
				}				
			}


		}
	}


	/**  chat  **/
	.b-dr{
		&_wrap{
			display: flex;
			justify-content: space-between;

		}
		&_left{
			flex-basis: 69%;
			@include respond-to(small){
				flex-basis: 100%;
			};
		}
		&_bottle{
			flex-basis: 28%;
			margin-right: -3%;
			img{
				max-width: 100%;
				@include vw($width: 400);
			}
		}

	}
	.b-chat{
		background: #f2f2f2;
		@include vw($margin: 10 0 0 0);
		position: relative;
		&_header{
			background: #e6e6e6;
			width: 100%;
			position: relative;
			@include vw($padding: 20,
				$font-size: 26,
				$letter-spacing: 1);
			text-align: center;
			span{
				display: inline-block;
				color: $brand;
				border-radius: 50%;
				position: absolute;
				text-align: center;
				top: 50%;
				transform: translateY(-50%);
				cursor: pointer;
				font-family: $ff-sec;
				@include vw($width: 50,
					$height: 50,
					$line-height: 50,
					$font-size: 36,
					$border: 1.5 solid $brand,
					$right: 20);
			}
		}
		&_wrap{
			@include vw(
			$padding: 0 0 70);
			@include vw($height: 540,
				$padding: 25 30 95);
			overflow-x: hidden;
			overflow-y: auto;
			width: 100%;
			position: relative;
		}
		&_body{

		}
		&_footer{
			/*			position: absolute;
						bottom: 0;
						left: 0;*/

			position: relative;
		}
		&_doctor{
			display: flex;
			align-items: center;
			@include vw($margin-bottom: 40);
			&_img{
				overflow: hidden;
				flex-grow: 0;
				z-index: 9;
				@include vw($flex-basis: 270,
					$min-height: 270,
					$border:  10 solid #999999 );
				border-radius: 50%;
				@include respond-to(small){
					width: 150px;
					height: 150px;
					flex-grow: 0;
				};
				@include respond-to(xs){
					width: 140px;
					flex-basis: 140px;
					height: 140px;
					min-height: auto;
					flex-grow: 0;
					position: relative;
					z-index: 9;
				};
				img{
					width: 100%;
					transform: scale(1.2);
				}
			}
			p{
				background: #ccc;
				@include vw($flex-basis: 640,
					$padding: 20 20 20 70,
					$margin: 0 0 0 -40,
					$font-size: 28,
					$border-radius: 20);
				@include respond-to(small){
					flex-basis: 70%;
					flex-grow: 0;
					margin-left: -40px;
					font-size: 16px;
				};
				@include respond-to(xs){
					flex-basis: 78%;
					flex-grow: 0;
					margin-left: -45px;
					font-size: 14px;
				};
			}

		}
		.b-dr_assessment_content {
			header{
				width: 100%;
			}
			p{
				@include vw($font-size: 30);
				@include respond-to(small){
					font-size: 22px;
				};
				@include respond-to(xs){
					font-size: 16px;
				};
			}
		}
		&_message{
			/*width: 80%;*/
			background: #ccc;
			clear: both;

			@include vw($padding: 13 30 8,
				$border-radius: 15,
				$margin-bottom: 20,
				$font-size: 40);
			position: relative;
			max-width: 80%;
			@include respond-to(small){
				font-size: 22px;
			};
			@include respond-to(xs){
				font-size: 18px;
				max-width: 90%;
			};
			&_dr{
				float: left;
				&:before{
					content: '';
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					@include vw($border: 10.5 solid transparent,
						$border-right: 10.5 solid #ccc,
						$left: -20);
				}
			}
			&_client{
				float: right;
				text-align: right;
				background: $l-blue;
				/*				div{
									@include vw($padding: 10 30 8,
								$border-radius: 15,
								$margin-bottom: 20,
								$font-size: 40);
								}*/
				&:before{
					content: '';
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					transition: all .3s ease-in;
					@include vw($border: 10.5 solid transparent,
						$border-left: 10.5 solid $l-blue,
						$right: -20);
				}
			}
		}
		&_input{
			width: 80%;
			height: 100%;
			@include vw($padding: 0 20,
				$font-size: 36,
				$line-height: 70,
				$border-radius: 17);
			@include respond-to(small){
				font-size: 22px;
			};
			@include respond-to(xs){
				font-size: 16px;
				line-height: 40px;

			};
			&:empty{
				&:before{
					content:"How old are you";
					color: #999;
				}	
			}
			&.choose{
				&:empty{
					&:before{
						content:"select your option above";
						color: #999;
					}	
				}
			}
			&.empty{
				&:empty{
					&:before{
						content:"";
						color: #2B8EC2;
					}	
				}
			}
			&_bg{

				background: #fff;
				/*position: relative;*/
				@include vw($height: 70,
					$border-radius: 17);
				@include respond-to(xs){
					height: 40px;
				};
			}
			&_wrap{
				position: relative;
				width: 100%;
				background: #e6e6e6;
				@include vw($padding: 30 45)
					@include respond-to(small){
					padding: 10px;
				};
			}
			&_send{
				background: $brand;
				position: absolute;
				text-transform: uppercase;
				color: #fff;
				border: none;
				@include vw($width: 160,
					$height: 55,
					$line-height: 60,
					$right: 50,
					$top: 35,
					$border-radius: 25,
					$font-size: 32);
				@include respond-to(small){
					font-size: 20px;
					top: 16px;
					right: 18px;

				};
				@include respond-to(xs){
					font-size: 18px;
					height: 30px;
					width: 90px;
					line-height: 33px;
				};
				&:hover{
					background: #CBE4EF;
					color: #000;
				}
			}
		}
		&_typing{
			position: absolute;
			text-align: center;
			width: 100%;
			@include vw($font-size: 14,
				$top: -30);
			color: #2B8EC2;
		}
		.b-dr_slider_group{
			@include respond-to(small){
				width: 100%;
			};
		}
		.l-radio+label{
			background: #999;
			text-align: center;
			color: #fff;
			@include vw($min-width: 100,
				$padding: 12 10 10,
				$margin-bottom: 10);
			@include respond-to(xs){
				font-size: 16px;
				margin-bottom: 5px;
			};
			span{
				color: #fff;
				/*font-family: $ff-heavy;*/
				font-weight: bold;
				display: block;
				@include vw($font-size: 28
					);
				@include respond-to(xs){
					font-size: 16px;
				};
			}
			&:before, &:after{
				content: '';
				display: none;
			}
			&:hover{
				background: $l-blue;
			}
			&.chosen{
				cursor: default;
				background: #999;
			}
		}
		.l-radio:checked+label{
			background: $l-blue;
		}
		.b-dr_slider_group.r-width{
			justify-content: flex-start;
			label{
				/*width: 60%;*/
				@include respond-to(small){
					width: 100%;
				};
			}
		}

		&_email{
			@include vw($padding: 30 20);
			position: relative;
			display: none;
			button{
				background: rgb(172, 232, 248);
				text-transform: uppercase;
				color: #000;
				border: none;
				@include vw($width: 190,
					$height: 55,
					$line-height: 60,
					$right: 50,
					$top: 35,
					$border-radius: 25,
					$font-size: 32);
				&:hover{
					background: $brand;
				}
			}
			&_first{
				display: flex;
				justify-content: space-between;
				align-items: center;

				p{
					flex-basis: 70%;
					@include vw($font-size: 34,
						$margin-bottom: 0);
				}
				button{
					flex-basis: 25%;
				}
			}
			&_sec{
				display: none;
				form{
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;
					align-items: center;
					label{
						display: none;
					}
					.form-group{
						flex-basis: 70%;
						margin-bottom: 0px;
						.form-control {
							display: block;
							width: 100%;
							color: #333;
							@include vw($height: 56,
								$padding: 10 15 8,
								$font-size: 24,
								$border-radius: 0,
								$border: 1 solid $l-blue);
							border: none;
							background: rgb(236, 250, 254);
							@include placeholder(){
								color: #000;
								text-align: center;
							};
						}
					}
					.help-block{
						display: none !important;
					}
					button{
						flex-basis: 25%;
					}
					.b-contact_subscribe_request{
						flex-basis: 100%;
					}
					.b-contact_subscribe_request{
						text-align: center;
						@include vw($padding: 20 0 0,
							$font-size: 22);
					}
				}
			}
		}

	}



	/***  review  ***/
	.b-review{
		&_foto{
			.l-reviews_container{
				@include vw($padding: 10 15);
				background: #f9f9f9;
				@include vw($margin-bottom: 20);
			}
			&_wrap{
				width: 60%;
				@include vw($margin: 0 auto 30);
				@include respond-to(xs){
					width: 94%;
				};

				p{
					text-align: center;
					@include vw($font-size: 20);
				}

			}
			img{
				text-align: center;
				max-width: 60%;
				@include vw($width: 500);
				margin: auto;
				display: block;
				@include respond-to(xs){
					max-width: 100%;
				};

			}
		}
	}
	/***  attention  ***/	
	.b-attention{
		&_wrap{
			@include vw($padding: 75 25);
			background: #ccc;
			box-shadow: 0 1.4vw 2.6vw -1.22vw hsla(0,0%,60%,.42), 0 0.4vw 2.3vw 0 rgba(0,0,0,.12), 0 0.8vw 1vw -0.5vw hsla(0,0%,60%,.2);
			box-shadow: 0.18vw 0.18vw 0.3vw 0.1vw hsla(0,0%,0%,.3);
			@include respond-to(small){
				padding: 35px 15px;
			};
		}

		p{
			line-height: 1.7;
			width: 94%;
			margin: 0 auto;
			@include vw($font-size: 28);
			font-family: $ff-sec;
			@include respond-to(small){
				font-size: 18px;
			};
			@include respond-to(xs){
				font-size: 16px;
			};
		}

		&_sign{
			text-align: right;
		}
	}
	/***  Content  ***/
	.b-content{
		background: linear-gradient(0deg, #d2e4ea 0%, #ddeaef 60%,  #fafafa 100%);

		.b-about{
			&_title{
				@include vw(
				$margin-bottom: 105);
				span{
					display: block;
					@include vw($margin: 10 0);
				}
			}
			section{
				@include vw($margin-bottom: 80);
			}
			p{
				@include vw($font-size: 18.62);
				/*				span{
									font-family: $ff-heavy;
									color: $brand;
								}*/
			}
			.l-quote{
				color: $brand;
				text-align: center;
				width: 94%;
				margin: 0 auto;
				/*font-family: $ff-heavy;*/
				@include vw($font-size: 24,
					$margin: 30 auto 40);
			}
			&_tabs{
				display: flex;
				justify-content: space-between;
				li{
					flex-basis: 33.3%;
					text-align: center;
					color: #fff;
					background: $brand;
					@include vw(
					$border: 9 solid #fff,
						$border-bottom: 0);
					@include respond-to(small){

						border-width: 3px;
						border-bottom: 0;
					};
					a{
						color: #fff;
						margin-right: 0px;
						border: 0px solid transparent;
						border-radius: 0px 0px 0 0;
						@include vw($padding: 28 15 24,
							$font-size: 30);
						@include respond-to(small){
							padding: 13px 5px 11px;
							font-size: 14px;
						};
						@include respond-to(xs){
							font-size: 13px;
						};
						&:hover{
							color: $brand;
						}
					}
					&.active{
						background:  #fff;
						@include vw(
						$border: 9 solid $brand,
							$border-bottom: 0);
						@include respond-to(small){

							border-width: 3px;
							border-bottom: 0;
						};
						a{

							color: $brand;
							border-radius: 0px 0px 0 0;


						}
					}
				}
			}
			&_tab{
				&_content{
					@include vw($border: 9 solid $brand,
						$padding: 60 40);
					@include respond-to(small){
						border: 3px solid #29abe2;
						padding: 22px 18px;
					};
					p{
						line-height: 1.5;
						@include vw($font-size: 23);
					}
				}
			}

		}
		.b-film{
			width: 90%;
			margin: 0 auto;
			&_item{
				@include vw($margin-bottom: 50);
			}
			h3{
				@include vw($font-size: 24,
					$margin-bottom: 20);
				text-align: center;
				/*display: none;*/
			}
			&_discript{
				@include vw($font-size: 14);
			}
			&_video {
				display: none;
				position: relative;
				margin: auto;
				padding-bottom: 45%;
				@include vw($border: 1.9 solid #eee,
					$margin-bottom: 30);
				&_overlay {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: #fff;
					display: flex;
					margin: auto;
					cursor: pointer;
					p {
						@include vw($font-size: 36,
							$margin: auto);
						a{
							@include vw($margin-top: 20);
						}
					}
				}
				iframe {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
		}
	}


}


.b-ask_modal{
	.modal-body{

		text-align: left;
	}
	header{
		text-align: left;
	}
	label{
		width: 100%;
		display: block;
		text-align: left;
		color: $brand;
		font-family: $ff-sec;
		font-weight: 600;
		@include vw($font-size: 19,
			$margin-bottom: 5);
	}
	input{
		margin: 0;
		background: $l-grey;
		border: none;
		color: #fff;
		width: 40%;

		@include vw($padding: 0 7,
			$height: 35,
			$line-height: 30,
			$min-width: 200,
			$box-shadow: 0 0 3 #fff inset,
			$margin-bottom: 15);
		@include placeholder{
			color: #fff;

		};
		@include respond-to(xs){
			height: 30px;
		};
	}
	textarea{
		width: 100%;
		@include vw($min-height: 240);
	}
}
.b-fb{
	display: inline-block;
	position: fixed;
	color: #fff;
	background: #1787FB;
	z-index: 999;
	@include vw($width: 250,
		$bottom: 7,
		$left: 5,
		$padding: 5 7 5,
		$border-radius: 7);
	a{
		color: #fff;
		font-weight: bold;
		display: block;
		@include vw($line-height: 50);
		span{
			display: inline-block;
			vertical-align: middle;
			background: url(/images/fb.png) no-repeat; 

			@include vw($width: 45,
				$height: 45,
				$background-position: 0 -47,
				$margin: 0 15 0  );

		}
	}
}